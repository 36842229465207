import styled, { css } from 'styled-components'
import mediaqueries, { BREAKPOINTS } from '../../../mediaQueries'
import { PageTitle } from '../../styles/text'

export const BreadCrumbContainer = styled.div`
  padding: 0;

  ${props => props.isAnnouncements
    ? css`
      margin: 24px 0 0 24px!important;
    `
    : css`
      ${props => props.isAssessment && `
          margin-left: 0;
          padding-left: 0;
      `}
        @media (max-width: 767px) {
          padding-left: 0px;
        }
        @media (max-width: 575px) {
          padding-left:${props => !props.isAssessment && '12px'}
        }

        ${mediaqueries.tablet`
          ${({ isActiveLearning }) => isActiveLearning && `
              padding: 0px 24px !important;
          `}
        `}
        @media (min-width: ${BREAKPOINTS.mobile}px) {
          margin-bottom: ${props?.marginBottom?.desktop
  // eslint-disable-next-line
            ? `${props.marginBottom.desktop}px`
  // eslint-disable-next-line
            : '24px'};
        }
        @media (max-width: ${BREAKPOINTS.mobile}px) {
          margin-bottom: ${props?.marginBottom?.mobile
  // eslint-disable-next-line
          ? `${props.marginBottom.mobile}px`
  // eslint-disable-next-line
          : '3rem'};
        }
        @media (max-width: ${BREAKPOINTS.small}px) {
          margin-bottom: ${props?.marginBottom?.small
  // eslint-disable-next-line
            ? `${props.marginBottom.small}px`
  // eslint-disable-next-line
            : '36px'};
          ${({ isActiveLearning }) => isActiveLearning && `
              padding: 0px 24px !important;
          `}
        }
    `}

`

export const BreadCrumbWrapper = styled.div`
  cursor: pointer;
  width: fit-content;
  color: #5FC4B8;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-top: ${props => props.marginTop};
  line-height: 17px;
  ${props => props.isAssessment && `
    margin-left: 24px;
    margin-top: 18px;
    width: 263px;
    height: 48px;
    padding-top: 15px;
    padding-left: 2px;
`}
  ${({ isActiveLearning }) => isActiveLearning && `
    margin-top: 27px;
  `}

  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      opacity: 0.3;
    }
    &:hover {
      filter: brightness(1.5);
    }
  }

  ${mediaqueries.mobile`
    ${({ isActiveLearning }) => isActiveLearning && `
      margin-top: 13px;
    `}
  `}
`

export const Caret = styled.span`
  width: 9px;
  height: 9px;
  position: relative;
  top: -.5px;
  border: 2px solid #5FC4B8;
  border-top-width: 0;
  border-right-width: 0;
  margin-right: 12px;
  transform: rotate(45deg);
`

export const Pagetitle = styled(PageTitle)`
  text-align: center;
  @media (min-width: ${BREAKPOINTS.mobile}px) {
    margin-top: ${props => props.marginTop.desktop}px;
  }
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    margin-top: ${props => props.marginTop.mobile}px;
  }
`
