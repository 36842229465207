import React, { Component } from 'react'
import { emitter } from '../Emitter/Emitter'
import ChapterListProgress from '../ChapterListItem/ChapterListProgress'
import Context from '../Context/Context'
import {
  getExamStartDate,
  getExamRedirectQuestionUUID,
  isPracticeExam,
  getChapterLockDates,
  getExamDatesFromExamRetakes
} from '../../utilities/chapterUtils'
import config from '../../config'
import {
  secondsToFormattedDateShort,
  getDifferenceOfDaysWithCurrentDate,
  subtractDaysFromDateInSecs,
  secondsSinceEpoch,
  dateToSecondsSinceEpoch,
  getTimezoneShort,
  addDaysToDateInSecs,
  secondsToFormattedDateTimeShort
} from '../../utilities/dateTimeUtils'
import { getMobileDevice } from '../../utilities/mobileDeviceUtils'
import {
  EXAM_COMPLETE, EXAM_PERCENTAGE
} from '../../Constants/studentContext'
import {
  ON_NAVIGATE_TO_EXAM,
  ON_REVIEW_MODE
} from '../../Constants/emitterKeys'
import {
  ErrorMessage,
  ChapterExamContentWrapper,
  ChapterTitle,
  ChapterWrapper,
  ChapterExamWrapper,
  LockText,
  UnlockText,
  ExamStudyGuideButton,
  StudyGuideUnlockText,
  StudyGuideWrapper,
  StudioCohortSignWrapper,
  TealText,
  StudioCohortText,
  ScoreText,
  ChapterExamWrapperInner
} from './styled'
import {
  PRACTICE_EXAM,
  SET_UP_YOUR_EXAM
} from '../../Constants/examType'
import { history } from '../HistoryManager/HistoryManager'
import camelCase from 'lodash/camelCase'
import {
  getProctorioExamTag,
  getProctorioExamTake,
  isExamRetakeType,
  storeAuthToken,
  allowLiveProctoring
} from '../../utilities/examUtils'
import api from '../../api'
import { WITHDRAW } from '../../Constants/studentStatus'
import { getExamExtensionLockDates } from '../ExtensionRequestPage/utils'
import DeadlineWarningComponent from '../DeadlineWarningComponent/DeadlineWarningComponent'
import ExamListItemModal from '../ExamListItemModal/ExamListItemModal'
import { getIsVIPCohort, isHighSchoolCourse } from '../../utilities/courseUtils'
import { EXAM } from '../../Constants/chapterType'

const DAYS = 10

class ExamListItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      totalPer: 0,
      totalPerQuiz: 0,
      showModal: false,
      showErrorOnMobile: false
    }
    this.chapterRef = React.createRef()
    this.getStudentData = this.getStudentData.bind(this)
    this.onExamClick = this.onExamClick.bind(this)
    this.getExamSupportingText = this.getExamSupportingText.bind(this)
  }

  componentDidMount () {
    this.getStudentData()
    this.scrollToSection()
    this.setExamCompletion()
  }

  componentDidUpdate () {
    this.scrollToSection()
  }

  setExamCompletion () {
    const {
      chapter,
      chapter: {
        chapter_uuid: chapterUUID,
        examVersions
      }
    } = this.props
    const { studentData, cohortData, examRetake } = this.context

    const isRetakeAllowed = isExamRetakeType({
      examRetake, chapter, cohortId: cohortData?.cohortID
    })

    const isExamPractice = isPracticeExam(chapterUUID)
    const isVersionACompleted = isExamPractice
      ? false
      : studentData[EXAM_COMPLETE][chapterUUID]
    const isRetakeComplete = !!studentData?.[EXAM_PERCENTAGE]?.[examVersions?.[0]?.uuid]
    const isExamCompleted = isRetakeAllowed ? isRetakeComplete : isVersionACompleted

    this.setState({ isExamCompleted })
  }

  scrollToSection = () => {
    const {
      location: { hash }
    } = history
    const {
      chapter: { title }
    } = this.props
    const hasScrollHash = hash.slice(1) === camelCase(title)
    if (hasScrollHash) {
      this.chapterRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  getStudentData () {
    var { chapter } = this.props
    const examPercentage = this.context.studentData['exam-percentage']
    const examCompleted = this.context.studentData['exam-complete']
    const { examRetake, cohortData } = this.context
    let totalPer = 0
    let totalPerQuiz = 0
    let totalQuiz = 0

    const { chapter_uuid: chapterUUID, examVersions } = chapter
    const isRetakeAllowed = isExamRetakeType({
      examRetake,
      cohortId: cohortData?.cohortID,
      chapter
    })
    const versionUUID = examVersions?.[0]?.uuid
    const uuid = isRetakeAllowed ? versionUUID : chapterUUID
    if (examCompleted[chapterUUID]) {
      totalPer = 100
    }

    if (uuid in examPercentage) {
      totalPerQuiz = examPercentage[uuid]
      totalQuiz = 1
    }

    totalPerQuiz = totalPerQuiz / totalQuiz
    this.setState({
      totalPer: totalPer,
      totalPerQuiz: totalPerQuiz
    })
  }

  async onExamClick (closeReviewModeForStudio) {
    const {
      updateContext,
      isCohortEndedForStudent,
      examRetake,
      cohortData
    } = this.context

    const { chapter } = this.props

    const isRetakeAllowed = isExamRetakeType({
      examRetake,
      cohortId: cohortData?.cohortID,
      chapter
    })

    if (isCohortEndedForStudent && !isRetakeAllowed) return
    const isMobileDeviceDetected = getMobileDevice()
    const {
      isExamCompleted
    } = this.state

    if (closeReviewModeForStudio) return

    if (isMobileDeviceDetected && !isExamCompleted) {
      return this.setState({ showErrorOnMobile: true })
    }
    const byPassProctoringService = this.shouldBypassProctoringService()

    if (!byPassProctoringService) {
      // store token to access it in proctorio
      const token = await api.getToken()
      storeAuthToken(token)
    }

    const redirectUrl = await this.getProctorioUrls()

    if (!redirectUrl) return

    if (isExamCompleted) {
      emitter.emit(ON_REVIEW_MODE, true)
    } else {
      emitter.emit(ON_REVIEW_MODE, false)
    }
    updateContext({ navigateToActiveIndex: true })
    window.outlierLog('Exam start', 'EXAM')
    emitter.emit(ON_NAVIGATE_TO_EXAM, redirectUrl)
  }

  getProctorioUrls = async () => {
    const { chapter } = this.props
    const {
      isVIP,
      cohortData,
      examRetake
    } = this.context
    const { examVersions } = chapter || []
    const examRedirectQuestionUUID = getExamRedirectQuestionUUID({
      cohortId: cohortData?.cohortID,
      chapter,
      examRetake,
      examVersions
    })

    if (isVIP) return
    const byPassProctoringService = this.shouldBypassProctoringService()
    const { exams } = chapter
    const questionUUID = examRedirectQuestionUUID ||
      exams.Question[0].Question_uuid
    if (byPassProctoringService) {
      return `#/${chapter.chapter_uuid}/${questionUUID}`
    }

    const {
      cohortData: { name: cohortName } = {}
    } = this.context

    const examTake = await getProctorioExamTake({
      courseBaseUrl: config.courseBaseUrl,
      examUUID: chapter.chapter_uuid,
      firstQuestionUUID: questionUUID,
      isExamRetake: !!examRedirectQuestionUUID
    })
    const examTag = getProctorioExamTag(cohortName, chapter)

    const proctorioUrls = await api.getProctorioUrls(examTake, examTag)
    return proctorioUrls?.launchUrl
  }

  shouldBypassProctoringService = () => {
    const {
      isVIPGradedContent,
      isAdmin,
      cohortData
    } = this.context

    const isLiveProctoring = allowLiveProctoring(cohortData)

    return !!(isAdmin ||
      isVIPGradedContent ||
      this.state.isExamCompleted ||
      isLiveProctoring)
  }

  /* 1. If it is a final exam chapter, return Chapters 1 - (total number of chapters)
     2. If description text has from chapter name and to chapter name,
     derive corresponding chapter numbers from props variable chapters.
     3. If description text has from chapter number and to chapter number,
     remove & and separate them by hyphen (-) */
  getExamSupportingText (chapterDescription) {
    const { cohortData } = this.context
    const { chapters, chapter } = this.props
    const { courseResourcesSchedule } = cohortData || {}

    const matchingExam = courseResourcesSchedule?.find((exam) =>
      exam?.assignmentType === EXAM &&
      exam?.examNumber === chapter?.examNumber
    )
    const materialCovered = matchingExam?.materialCovered
    if (materialCovered) {
      return materialCovered.startsWith('Covers')
        ? materialCovered : `Covers ${materialCovered}`
    }

    const isFinalExam = chapter.isFinalExam
    const additionalText = this.getAdditionalText(chapterDescription, isFinalExam)

    if (isFinalExam) {
      const lessons = chapters.filter(chapter => chapter.type === 'chapter')
      const { use0BasedIndexing } = config.course
      const startIndex = use0BasedIndexing ? 0 : 1
      const endIndex = use0BasedIndexing ? lessons.length - 1 : lessons.length
      return `Covers Chapters ${startIndex} - ${endIndex}${additionalText}`
    }
    const description = chapterDescription.toLowerCase()
    const splitText = description.includes('from chapters') ? 'from chapters' : 'from'
    const offsetIndex = splitText === 'from chapters' ? 14 : 5
    const chapterDetails = description.substring(description.indexOf(splitText) + offsetIndex,
      description.indexOf('.'))
    if (chapterDetails.includes(' & ')) {
      const chapterNumbers = chapterDetails.split(' & ')
      return chapterNumbers && chapterNumbers.length === 2 &&
      `Covers Chapters ${chapterNumbers[0]} - ${chapterNumbers[1]}${additionalText}`
    }
    if (chapterDetails.includes(' to ')) {
      // Chapter title can contain ' to '. Refer Chapter 1 of Astronomy.
      const chapterNameArray = chapterDetails.split(' to ')
      // Check if chapterNameArray[0] is a valid chapter name
      // If not Check if `${chapterNameArray[0]} to ${chapterNameArray[1]}` is a valid chapter name
      // ... and so on ...
      // When a valid `fromChapterName` is found, join remaining elements to get `toChapterName`
      let toChapterName, fromChapterIndex, toChapterIndex
      let fromChapterName = chapterNameArray[0]
      for (let i = 0; i < chapterNameArray.length - 1; i++) {
        // eslint-disable-next-line no-loop-func
        const { chapterNumber } = chapters.find((chapter) =>
          chapter.title.toLowerCase() === fromChapterName) || {}
        if (chapterNumber !== undefined) {
          fromChapterIndex = chapterNumber
          toChapterName = chapterNameArray.slice(i + 1).join(' to ')
          // eslint-disable-next-line no-loop-func
          const { chapterNumber: toChapterNumber } = chapters.find((chapter) =>
            chapter.title.toLowerCase() === toChapterName) || {}
          toChapterIndex = toChapterNumber
          break
        }
        fromChapterName = `${fromChapterName} to ${chapterNameArray[i + 1]}`
      }
      return `Covers Chapters ${fromChapterIndex} - ${toChapterIndex}${additionalText}`
    }
    return ''
  }

  /* Midterm exams have 2 lines of description with second line being additional text;
  while final exam has additional text in the first line enclosed within brackets.
  This function splits description text and returns last value of the split array */
  getAdditionalText (chapterDescription, isFinalExam) {
    const finalExamSplitText = chapterDescription.includes('all Chapters')
      ? 'all Chapters' : 'all chapters'
    const splitText = isFinalExam ? finalExamSplitText : '. '
    const descriptionLines = chapterDescription.split(splitText)
    const additionalText = descriptionLines && descriptionLines.length > 1 ? descriptionLines.pop() : ''
    if (!additionalText || additionalText === '') return ''
    return isFinalExam ? ` ${additionalText.trim()}` : `. ${additionalText.trim()}`
  }

  getExamSupportingTextComponent (text, isStudyGuideOpen) {
    const supportingText = {
      fontSize: '16px',
      lineHeight: '19px',
      marginTop: '8px',
      marginBottom: '0px',
      display: isStudyGuideOpen ? 'inline-block' : 'block'
    }
    return (<p style={supportingText} data-testid='supporting-text'> {text} </p>)
  }

  getExamStudyGuideButton = (examStudyGuide, chapterId) => {
    const { file: { url } = {} } = examStudyGuide || {}
    return (
      url && (
        <ExamStudyGuideButton
          data-testid='studyGuideButton'
          onClick={(e) => {
            e.stopPropagation()
            api.incrementStudyGuideClicks(chapterId)
            window.open(url, '_blank')
          }}
        >
          View Study guide
        </ExamStudyGuideButton>
      )
    )
  }

  getExamStudyGuideUnlockText = (examStudyGuide, formattedStudyGuideDate) => {
    const { file: { url } = {} } = examStudyGuide || {}
    if (!formattedStudyGuideDate || !url) return null
    return (
      <StudyGuideUnlockText data-testid='studyGuideUnlockText'>
        Study Guide unlocks {formattedStudyGuideDate}
      </StudyGuideUnlockText>
    )
  }

  handleModal = () => {
    this.setState(({ showModal: prevModalState }) => ({
      showModal: !prevModalState
    }))
  }

  getStudyGuideComponent = (props) => {
    const {
      isStudyGuideOpen,
      supportingText,
      examStudyGuide,
      chapterId,
      formattedStudyGuideDate
    } = props

    return (
      <StudyGuideWrapper column={!isStudyGuideOpen}>
        {supportingText && this.getExamSupportingTextComponent(supportingText, isStudyGuideOpen)}
        {isStudyGuideOpen
          ? this.getExamStudyGuideButton(
            examStudyGuide,
            chapterId
          )
          : this.getExamStudyGuideUnlockText(
            examStudyGuide,
            formattedStudyGuideDate
          )}
      </StudyGuideWrapper>
    )
  }

  getStudioCohortExamSign = () => {
    const { isExamCompleted } = this.state

    return (
      <StudioCohortSignWrapper>
        <img
          src='images/icons/exclamation-circle-1.svg'
          alt='exclamation-circle'
        />
        <StudioCohortText>
          {isExamCompleted
            ? 'This assessment has been submitted. Contact your instructor if you have any questions.'
            : 'This assessment can only be attempted once. Do not begin until directed to by your instructor.'
          }
        </StudioCohortText>
      </StudioCohortSignWrapper>
    )
  }

  getUnlockLockResonForRetake ({
    isPracticeExamOpen,
    startDateTime,
    endDateTime,
    timezone
  }) {
    let retakeUnlockReason, retakeLockReason
    if (isPracticeExamOpen) {
      retakeUnlockReason = `Closes: ${endDateTime} ${timezone}`
    } else {
      retakeLockReason =
      `Open from ${startDateTime} through ${endDateTime} ${timezone}`
    }
    return { retakeUnlockReason, retakeLockReason }
  }

  render () {
    const { chapter,
      cohortData: { cohortID } = {},
      cohortStartDate,
      cohortModifier,
      cohortSpecialDays,
      cohortExamDates
    } = this.props
    const cohortData = {
      cohortID,
      cohortStartDate,
      cohortModifier,
      cohortSpecialDays,
      cohortExamDates
    }
    const {
      activeCourse,
      studentData,
      adjustedGradeData,
      isCohortEndedForStudent,
      isStudioCohort,
      examRetake,
      originalCohortDeadlines,
      isAdmin,
      isVIP,
      latestCohort,
      cohortData: { name: cohortName } = {}
    } = this.context

    const {
      relationship: { fields: { liveProctoring } = {} } = {}
    } = latestCohort || {}

    const {
      totalPer,
      showModal,
      isExamCompleted
    } = this.state
    const {
      lockDate: updatedLockDate,
      originalLockDate
    } = getExamExtensionLockDates({
      chapter,
      cohortData,
      examRetake,
      originalCohortDeadlines
    })
    const isExtendedDeadline = updatedLockDate !== originalLockDate
    const { examStudyGuide, chapter_uuid: chapterId, examVersions } = chapter
    const { examStartDate, isUpcoming } = getExamStartDate(cohortExamDates, chapter)
    const diff = getDifferenceOfDaysWithCurrentDate(examStartDate)
    const isStudyGuideOpen = (typeof diff === 'number' && diff <= DAYS) || !isUpcoming

    let formattedStudyGuideDate
    if (!isStudyGuideOpen && examStartDate) {
      const studyGuideOpenDate = subtractDaysFromDateInSecs(examStartDate, DAYS)
      formattedStudyGuideDate = secondsToFormattedDateShort(
        studyGuideOpenDate
      )
    }

    const isPhilosophyStephensStudioCohort =
      config.philosophyStephensCollegeStudioCohortId === cohortID

    const practiceExam = chapter.title === PRACTICE_EXAM
    let { lockReason, studentStatus } = this.props
    let isPracticeExamOpen = true
    const { totalPerQuiz } = this.state
    const supportingText = this.getExamSupportingText(chapter.desc || '')
    let unlockReason = ''

    const isRetakeAllowed = isExamRetakeType({ examRetake, chapter: chapter, cohortId: cohortID })

    const {
      lockDate, unlockDate
    } = getChapterLockDates({ chapter, cohortData, examRetake })
    let lockDateSeconds = lockDate
    const currentDateSeconds = secondsSinceEpoch()
    if (!practiceExam) {
      const lockTextDateTime = secondsToFormattedDateTimeShort(
        lockDate, 'short'
      ).replace(', ', ' at ')
      const unLockTextDateTime = secondsToFormattedDateTimeShort(unlockDate,
        'short')
      const timezone = getTimezoneShort(lockDate)

      if (isRetakeAllowed) {
        const retake = examRetake?.find(retake => retake?.cohort?.id === cohortID)

        let startDate
        let endDate

        const {
          retakeStartDate,
          retakeEndDate
        } = getExamDatesFromExamRetakes({
          exam: chapter,
          examRetake: retake
        })

        if (retakeStartDate && retakeEndDate) {
          startDate = retakeStartDate
          endDate = retakeEndDate
        }

        lockDateSeconds = dateToSecondsSinceEpoch(new Date(endDate))
        const endDateTime = secondsToFormattedDateTimeShort(
          lockDateSeconds, 'short'
        ).replace(', ', ' at ')
        const startDateSeconds = dateToSecondsSinceEpoch(new Date(startDate))
        const startDateTime =
         secondsToFormattedDateTimeShort(startDateSeconds, 'short')
        isPracticeExamOpen = (lockDateSeconds > currentDateSeconds) &&
          startDateSeconds < currentDateSeconds
        const { retakeUnlockReason, retakeLockReason } = this
          .getUnlockLockResonForRetake({
            isPracticeExamOpen,
            startDateTime,
            endDateTime,
            timezone
          })
        unlockReason = retakeUnlockReason
        lockReason = retakeLockReason
      } else if (
        isCohortEndedForStudent &&
        !isVIP && studentStatus !== WITHDRAW
      ) {
        const cohortEndText = `Open from ${unLockTextDateTime}
          through ${lockTextDateTime} ${timezone}`
        if (!lockReason || totalPer === 100) {
          unlockReason = cohortEndText
        } else {
          lockReason = cohortEndText
        }
        if (isStudioCohort) unlockReason = 'Closed'
      } else if (studentStatus === WITHDRAW) {
        unlockReason = lockReason
      } else {
        unlockReason = `Closes: ${lockTextDateTime} ${timezone}`
        if (isStudioCohort && isExamCompleted) unlockReason = 'Closed'
      }
    } else {
      const { practiceExamOpenDate } = chapter
      isPracticeExamOpen = practiceExamOpenDate <= secondsSinceEpoch()
      lockDateSeconds = practiceExamOpenDate

      if (!isPracticeExamOpen) {
        const practiceExamStartDate = secondsToFormattedDateTimeShort(
          practiceExamOpenDate, 'short'
        )
        const timezone = getTimezoneShort(practiceExamOpenDate)
        unlockReason =
          `The practice exam will open on ${practiceExamStartDate} ${timezone}`
      }
    }

    const secondsBeforeLock = lockDateSeconds - currentDateSeconds
    const showDeadlineWarning = !isRetakeAllowed && !practiceExam && !liveProctoring &&
      secondsBeforeLock > 0 && secondsBeforeLock < 3 * 24 * 60 * 60
    const show24HrDeadlineWarning =
      showDeadlineWarning && secondsBeforeLock < 24 * 60 * 60 && !liveProctoring
    const unlockReasonColor = !showDeadlineWarning
      ? '#D6E7E7'
      : secondsBeforeLock > 24 * 60 * 60
        ? '#FFB800'
        : '#F2765A'

    const showStudioCohortExamSign =
      isStudioCohort && !practiceExam && !isCohortEndedForStudent

    /* Close review state before lock date and after 4 days of exam end date
     for only this studio cohort */
    const isPhilosophyStephensStudioCohortEnd =
      isPhilosophyStephensStudioCohort &&
      (currentDateSeconds < lockDate ||
      currentDateSeconds > addDaysToDateInSecs(lockDate, 4))
    const closeReviewModeForStudio =
      isStudioCohort && !practiceExam && isExamCompleted &&
      isPhilosophyStephensStudioCohortEnd
    const chapterTitle = chapter.title === PRACTICE_EXAM
      ? SET_UP_YOUR_EXAM
      : chapter.title

    const adjustedSection = adjustedGradeData?.sort(
      (a, b) => b?.timestamp - a?.timestamp)?.find(chapter =>
        chapterId === chapter.sectionUuid)

    const isAdminOrVipCohort = isAdmin || getIsVIPCohort(cohortName)

    const shouldHideForHighSchool =
      secondsBeforeLock > 0 && isExamCompleted && isHighSchoolCourse(activeCourse)

    // Disable Midterm Exam2 temporarily for CSI 5/15 cohort,
    // 7/18 from 12:00am until 6:30pm, check #8680 for more details
    const isMidtermExam2Disabled = chapter.title === 'Midterm Exam 2' &&
      cohortName === 'Computer Science I - 5/15/24' &&
      secondsSinceEpoch() > 1721275200 && // 7/18/24 12:00am EDT
      secondsSinceEpoch() < 1721341800 // 7/18/24 6:30pm EDT

    return (
      <div key={chapter.chapter_uuid} ref={this.chapterRef}>
        <ChapterWrapper>
          <ChapterExamWrapper
            data-testid='chapterExamWrapper'
          >
            <ChapterExamWrapperInner>
              <ChapterExamContentWrapper>
                <ChapterTitle data-testid='practice-title'>{chapterTitle}</ChapterTitle>
                {this.getStudyGuideComponent({
                  isStudyGuideOpen,
                  supportingText,
                  examStudyGuide,
                  chapterId,
                  formattedStudyGuideDate
                })}
                {practiceExam && this.getExamSupportingTextComponent(
                  isPracticeExamOpen ? config.practiceExamDescription
                    : config.getPracticeExamDescription(null)
                )}
                {!isAdminOrVipCohort && unlockReason && !lockReason && !isExamCompleted &&
                  <UnlockText
                    data-testid='unlock-text'
                    unlockReasonColor={unlockReasonColor}
                  >
                    {unlockReason}
                  </UnlockText>}
                {!isAdminOrVipCohort && lockReason && isNaN(totalPerQuiz) &&
                  <LockText data-testid='lock-text'>
                    {lockReason}
                  </LockText>}
                <div>
                  {!adjustedSection ? (
                    !isNaN(totalPerQuiz) && (
                      <ScoreText data-testid='score-text'>
                          Score: {Math.round(Number(totalPerQuiz))}%
                      </ScoreText>
                    )
                  ) : (
                    <ScoreText data-testid='score-text'>
                      <span>
                          Adjusted Score:{' '}
                        <TealText
                          data-testid='teal-text'
                          onClick={() => this.handleModal()}
                        >
                          {Math.round(adjustedSection.to)}%
                        </TealText>
                      </span>
                    </ScoreText>
                  )}
                </div>
              </ChapterExamContentWrapper>
              {adjustedSection && <ExamListItemModal
                section={adjustedSection}
                showModal={showModal}
                setShowModal={() => this.handleModal()}
              />}
              {
                this.state.showErrorOnMobile &&
                  <span className='d-flex justify-content-between align-items-start'>
                    <img src='./images/icons/icon-warn.svg' alt='warn' />
                    <ErrorMessage>
                      Exams are not supported on this device. Please use a laptop or desktop computer instead.
                    </ErrorMessage>
                  </span>
              }
            </ChapterExamWrapperInner>
            {(!isCohortEndedForStudent || isRetakeAllowed) && !closeReviewModeForStudio && !shouldHideForHighSchool &&
              <ChapterListProgress
                chapter_uuid={chapter.chapter_uuid}
                isDisabled={isMidtermExam2Disabled}
                isChapterComplete={this.state.totalPer === 100}
                isRetakeAllowed={isRetakeAllowed}
                isRetakeComplete={!!studentData?.[EXAM_PERCENTAGE]?.[examVersions?.[0]?.uuid]}
                showErrorOnMobile={this.state.showErrorOnMobile}
                isPracticeExamOpen={isPracticeExamOpen}
                navigateToChapter={async (restoreButtonState) => {
                  await this.onExamClick(closeReviewModeForStudio)
                  restoreButtonState()
                }}
                lockReason={lockReason}
              />}
            { showDeadlineWarning && (this.state.totalPer !== 100) &&
              !lockReason &&
              <DeadlineWarningComponent
                assignmentUUID={chapterId}
                isExtendedDeadline={isExtendedDeadline}
                show24HrDeadlineWarning={show24HrDeadlineWarning}
              />}
            {showStudioCohortExamSign && this.getStudioCohortExamSign()}
          </ChapterExamWrapper>
        </ChapterWrapper>
      </div>
    )
  }
}

ExamListItem.contextType = Context
ExamListItem.displayName = 'ExamListItem'
export default ExamListItem
