import { COURSE_IDS } from '../config'
import {
  KALTURA_CALCULUS_ID,
  KALTURA_OTHER_COURSES_ID,
  KALTURA_PHILOSOPHY_ID
} from '../Constants/orientationVideo'

const FLORIDA_POLY = {
  showTransferCreditMenuItem: false
}

const GGU = { }

// 39 week extended courses
const EXT = {}

// base class
const COURSE = {
  expandChapterListCollapse: false,
  hasActiveLearningTheme: false,
  showTransferCreditMenuItem: true,
  kalturaOrientationVideoId: KALTURA_OTHER_COURSES_ID,
  showStepByStepSolution: false,
  hideQuestionExplanation: false,
  shouldAddReadingsInSectionProgress: true,
  unlockExercises: false,
  unlockChapterOnAssessmentLock: true,
  hideChapterNumber: false,
  showReadingsAfterActiveLearning: false,
  readingsLabel: null,
  readingsTooltipText: 'Section readings. Read them here or download them onto your device.',
  showRegistration: true,
  showAIF: true,
  isCalculus: false,
  additionalCourseIds: [],
  showRegularQuotationInsideDoubleQuoteElement: false,
  openTimeManagementLinkInNewTab: true,
  useKalturaV7: false,
  isSingleMidtermExam: false,
  repeatGuardianPermissionRequest: false,
  allowPopQuestions: true,
  hasFinalExam: true,
  customGradingFirstParagraphText: ''
}

const CALCULUS = {
  ...COURSE,
  hasActiveLearningTheme: true,
  kalturaOrientationVideoId: KALTURA_CALCULUS_ID,
  showStepByStepSolution: true,
  isCalculus: true,
  showChecklistDropdownAL: true,
  showChecklistDropdownLectures: true,
  examRetakeVersions: true
}

const CALCULUS_PLUS = {
  ...CALCULUS,
  ...GGU
}

const CALCULUS_FP = {
  ...CALCULUS,
  ...FLORIDA_POLY,
  hasActiveLearningTheme: true
}

const PREVIEW_CALCULUS = {
  ...CALCULUS
}

const PREVIEW_BARNARD_HIGHLIGHTS = {
  ...COURSE
}

const PSYCHOLOGY = {
  ...COURSE,
  expandChapterListCollapse: true
}

const PSYCHOLOGY_PLUS = {
  ...PSYCHOLOGY,
  ...GGU,
  examRetakeVersions: true
}

const PREVIEW_PSYCHOLOGY = {
  ...PSYCHOLOGY
}

const WELCOME_PSYCHOLOGY = {
  ...PSYCHOLOGY
}

const STATISTICS = {
  ...COURSE,
  showRegularQuotationInsideDoubleQuoteElement: true,
  showStepByStepSolution: true
}

const STATISTICS_PLUS = {
  ...STATISTICS,
  ...GGU
}

const PREVIEW_STATISTICS = {
  ...STATISTICS
}

const PHILOSOPHY = {
  ...COURSE,
  examRetakeVersions: true,
  isSingleMidtermExam: true,
  hasFinalExam: false,
  kalturaOrientationVideoId: KALTURA_PHILOSOPHY_ID
}

const PHILOSOPHY_PLUS = {
  ...PHILOSOPHY,
  ...GGU
}

const PREVIEW_PHILOSOPHY = {
  ...PHILOSOPHY
}

const PREVIEW_MARKETING_PHILOSOPHY = {
  ...PHILOSOPHY
}

const MICROECONOMICS = {
  ...COURSE
}

const MICROECONOMICS_PLUS = {
  ...MICROECONOMICS,
  ...GGU
}

const PREVIEW_MICROECONOMICS = {
  ...MICROECONOMICS
}

const PRECALCULUS = {
  ...COURSE,
  shouldAddReadingsInSectionProgress: false
}

const PRECALCULUS_PLUS = {
  ...PRECALCULUS,
  ...GGU,
  allowPopQuestions: false
}

const COLLEGE_ALGEBRA = {
  ...COURSE,
  shouldAddReadingsInSectionProgress: false
}

const COLLEGE_ALGEBRA_PLUS = {
  ...COLLEGE_ALGEBRA,
  ...GGU
}

const PREVIEW_PRECALCULUS = {
  ...PRECALCULUS
}

const MACROECONOMICS = {
  ...COURSE
}

const MACROECONOMICS_PLUS = {
  ...MACROECONOMICS,
  ...GGU
}

const PREVIEW_MACROECONOMICS = {
  ...MACROECONOMICS
}

const FINANCIAL_ACCOUNTING = {
  ...COURSE
}

const FINANCIAL_ACCOUNTING_PLUS = {
  ...FINANCIAL_ACCOUNTING,
  ...GGU
}

const SOCIOLOGY = {
  ...COURSE
}

const SOCIOLOGY_PLUS = {
  ...SOCIOLOGY,
  ...GGU
}

const ASTRONOMY = {
  ...COURSE,
  additionalCourseIds: ['ckdf2158p00003g5milp6mvj8', 'ckzmyc33d00003e5z5ju9w077']
}

const ASTRONOMY_PLUS = {
  ...COURSE,
  ...GGU
}

const PRINCIPLES_OF_ECONOMICS = {
  ...COURSE,
  customGradingFirstParagraphText: `
      At the end of each chapter, you will be required to complete a quiz designed to assess your mastery of the material 
      covered in that chapter.`
}

const PRINCIPLES_OF_ECONOMICS_PLUS = {
  ...PRINCIPLES_OF_ECONOMICS,
  ...GGU,
  customGradingFirstParagraphText: ''
}

const PREVIEW_ASTRONOMY = {
  ...ASTRONOMY
}

const TEST = {
  ...COURSE,
  useKalturaV7: true,
  showJDoodleCompiler: true
}

const BUSINESS = {
  ...COURSE,
  readingsLabel: 'Business Plan',
  readingsTooltipText: 'Weekly Discussion Post. Read it here and respond on Yellowdig.',
  showReadingsAfterActiveLearning: true
}

const BUSINESS_PLUS = {
  ...BUSINESS,
  ...GGU
}

const PREVIEW_BUSINESS = {
  ...BUSINESS
}

const COLLEGE_WRITING_I = {
  ...COURSE,
  unlockChapterOnAssessmentLock: false,
  customGradingFirstParagraphText: `
      At the end of each chapter, you will be required to complete a quiz designed to assess your mastery of the material 
      covered in that chapter.`
}

const COLLEGE_WRITING_I_PLUS = {
  ...COLLEGE_WRITING_I,
  ...GGU,
  customGradingFirstParagraphText: '',
  additionalCourseIds: ['cl8bpkss900003b68lww2m8az', 'clpx286t000013b6buj1q8rgq']
}

const COLLEGE_WRITING_II = {
  ...COURSE,
  unlockChapterOnAssessmentLock: false,
  customGradingFirstParagraphText: `Quizzes are included at the end of chapters 1, 4, 5, and 13. They are designed to assess understanding of the 
      material covered in these chapters.`
}

const PREVIEW_COLLEGE_WRITING_II = {
  ...COLLEGE_WRITING_II
}

const COLLEGE_WRITING_II_PLUS = {
  ...COLLEGE_WRITING_II,
  ...GGU,
  customGradingFirstParagraphText: ''
}

const PREVIEW_SOCIOLOGY = {
  ...SOCIOLOGY
}

const PREVIEW_FINANCIAL_ACCOUNTING = {
  ...FINANCIAL_ACCOUNTING
}

const PREVIEW_COLLEGE_ALGEBRA = {
  ...COLLEGE_ALGEBRA
}

const PREVIEW_COLLEGE_WRITING_I = {
  ...COLLEGE_WRITING_I
}

const COLLEGE_SUCCESS = {
  ...COURSE,
  showRegistration: false,
  showAIF: false,
  hideChapterNumber: true,
  unlockExercises: true,
  readingsLabel: 'Resource Guides',
  readingsTooltipText: 'Lesson resource guides. Check them out here or download them onto your device.',
  showReadingsAfterActiveLearning: true,
  openTimeManagementLinkInNewTab: false
}

const COMPUTER_SCIENCE_I = {
  ...COURSE,
  use0BasedIndexing: true,
  showJDoodleCompiler: true,
  customGradingFirstParagraphText: `Quizzes are located at the end of chapters 0, 1, 2, 4, 5, 6, 8, 9, and 11. Quizzes are designed to assess mastery of 
      the material covered in the lectures and in the active learning (the interactive textbook) for that chapter. The
       material covered in chapters 3, 7, and 10 will each be assessed in the quizzes in the next chapter.`
}

const COMPUTER_SCIENCE_I_PLUS = {
  ...COMPUTER_SCIENCE_I,
  ...GGU,
  customGradingFirstParagraphText: ''
}

const PHYSICS_I = {
  ...COURSE
}

const CALCULUS_II = {
  ...COURSE
}

const PREVIEW_COMPUTER_SCIENCE_I = {
  ...COMPUTER_SCIENCE_I
}

const PREVIEW_PRINCIPLES_OF_ECONOMICS = {
  ...PRINCIPLES_OF_ECONOMICS
}

const MANAGERIAL_ACCOUNTING = {
  ...COURSE
}

const CONTENT = {
  ...COURSE
}

const PROFESSIONAL_COMMUNICATION = {
  ...COURSE,
  showZoomConsentForm: true,
  zoomConsentFormId: 'oaPXGr6U',
  under18ZoomConsentFormId: 'Kf4ngQRR',
  repeatGuardianPermissionRequest: true,
  customGradingFirstParagraphText: `Quizzes are located at the end of each of the following chapters: 1, 2, and 3 in unit 1, 6 and 7 in unit 2, and 10, 11,
       12, and 13 in unit 3. They are designed to assess mastery of the material covered in the lectures and in the active
       learning (the interactive textbook) for that chapter.`
}

const PREVIEW_PROFESSIONAL_COMMUNICATION = {
  ...PROFESSIONAL_COMMUNICATION,
  showZoomConsentForm: false
}

const PROFESSIONAL_COMMUNICATION_PLUS = {
  ...PROFESSIONAL_COMMUNICATION,
  ...GGU,
  customGradingFirstParagraphText: ''
}

const DATAANALYTICS_I_CERT = {
  ...COURSE,
  hasFinalExam: false
}

const DATAANALYTICS_II_CERT = {
  ...COURSE,
  hasFinalExam: false
}

const SALES_OPERATIONS_CERT = {
  ...COURSE,
  hasFinalExam: false
}

const GOOGLE_PROJECT_MANAGEMENT_CERT = {
  ...COURSE,
  hasFinalExam: false
}

const IT_SUPPORT_CERT = {
  ...COURSE,
  hasFinalExam: false
}

const BUSINESS_EXT = {
  ...EXT,
  ...BUSINESS
}

const SOCIOLOGY_EXT = {
  ...EXT,
  ...SOCIOLOGY
}

const PRINCIPLES_OF_ECONOMICS_EXT = {
  ...EXT,
  ...PRINCIPLES_OF_ECONOMICS,
  customGradingFirstParagraphText: ''
}

const COMPUTER_SCIENCE_I_EXT = {
  ...EXT,
  ...COMPUTER_SCIENCE_I,
  customGradingFirstParagraphText: ''
}

const CALCULUS_EXT = {
  ...EXT,
  ...CALCULUS
}

const PSYCHOLOGY_EXT = {
  ...EXT,
  ...PSYCHOLOGY
}

const STATISTICS_EXT = {
  ...EXT,
  ...STATISTICS
}

const COLLEGE_ALGEBRA_EXT = {
  ...EXT,
  ...COLLEGE_ALGEBRA
}

const PRECALCULUS_EXT = {
  ...EXT,
  ...PRECALCULUS
}

const FINANCIAL_ACCOUNTING_EXT = {
  ...EXT,
  ...FINANCIAL_ACCOUNTING
}

const PROFESSIONAL_COMMUNICATION_EXT = {
  ...EXT,
  ...PROFESSIONAL_COMMUNICATION,
  customGradingFirstParagraphText: ''
}

const DIGITAL_MARKETING_I_CERT = {
  ...COURSE,
  showZoomConsentForm: true,
  zoomConsentFormId: 'x0Ddd82J',
  under18ZoomConsentFormId: 'akOSqwUt',
  repeatGuardianPermissionRequest: true,
  hasFinalExam: false
}

const DIGITAL_MARKETING_II_CERT = {
  ...COURSE,
  hasFinalExam: false
}

const PREVIEW_DATAANALYTICS_I_CERT = {
  ...DATAANALYTICS_I_CERT
}

const PREVIEW_GOOGLE_PROJECT_MANAGEMENT_CERT = {
  ...GOOGLE_PROJECT_MANAGEMENT_CERT
}

const PREVIEW_IT_SUPPORT_CERT = {
  ...IT_SUPPORT_CERT
}

const PREVIEW_DIGITAL_MARKETING_I_CERT = {
  ...DIGITAL_MARKETING_I_CERT
}

const PREVIEW_SALES_OPERATIONS_CERT = {
  ...SALES_OPERATIONS_CERT
}

export const CourseFactory = courseId => {
  switch (courseId) {
    case (COURSE_IDS.calculus): return CALCULUS
    case (COURSE_IDS['calculus.plus']): return CALCULUS_PLUS
    case (COURSE_IDS['y.calculus']): return CALCULUS_FP
    case (COURSE_IDS['preview.calculus']): return PREVIEW_CALCULUS
    case (COURSE_IDS.psychology): return PSYCHOLOGY
    case (COURSE_IDS['psychology.plus']): return PSYCHOLOGY_PLUS
    case (COURSE_IDS['preview.psychology']): return PREVIEW_PSYCHOLOGY
    case (COURSE_IDS['welcome.psychology']): return WELCOME_PSYCHOLOGY
    case (COURSE_IDS.statistics): return STATISTICS
    case (COURSE_IDS['statistics.plus']): return STATISTICS_PLUS
    case (COURSE_IDS['preview.statistics']): return PREVIEW_STATISTICS
    case (COURSE_IDS.philosophy): return PHILOSOPHY
    case (COURSE_IDS['philosophy.plus']): return PHILOSOPHY_PLUS
    case COURSE_IDS.principlesofeconomics: return PRINCIPLES_OF_ECONOMICS
    case COURSE_IDS['principlesofeconomics.plus']: return PRINCIPLES_OF_ECONOMICS_PLUS
    case (COURSE_IDS.barnardhighlights): return PREVIEW_BARNARD_HIGHLIGHTS
    case (COURSE_IDS['preview.philosophy']): return PREVIEW_PHILOSOPHY
    case (COURSE_IDS['preview-marketing.philosophy']): return PREVIEW_MARKETING_PHILOSOPHY
    case (COURSE_IDS.microeconomics): return MICROECONOMICS
    case (COURSE_IDS['microeconomics.plus']): return MICROECONOMICS_PLUS
    case (COURSE_IDS['preview.microeconomics']): return PREVIEW_MICROECONOMICS
    case (COURSE_IDS.precalculus): return PRECALCULUS
    case (COURSE_IDS['precalculus.plus']): return PRECALCULUS_PLUS
    case COURSE_IDS['physics-i']: return PHYSICS_I
    case COURSE_IDS['calculus-ii']: return CALCULUS_II
    case COURSE_IDS.professionalcommunication: return PROFESSIONAL_COMMUNICATION
    case COURSE_IDS['professionalcommunication.plus']: return PROFESSIONAL_COMMUNICATION_PLUS
    case COURSE_IDS['preview.professionalcommunication']: return PREVIEW_PROFESSIONAL_COMMUNICATION
    case (COURSE_IDS.collegealgebra): return COLLEGE_ALGEBRA
    case (COURSE_IDS['collegealgebra.plus']): return COLLEGE_ALGEBRA_PLUS
    case (COURSE_IDS['preview.precalculus']): return PREVIEW_PRECALCULUS
    case (COURSE_IDS.macroeconomics): return MACROECONOMICS
    case (COURSE_IDS['macroeconomics.plus']): return MACROECONOMICS_PLUS
    case (COURSE_IDS['preview.macroeconomics']): return PREVIEW_MACROECONOMICS
    case (COURSE_IDS['preview.financialaccounting']): return PREVIEW_FINANCIAL_ACCOUNTING
    case (COURSE_IDS['preview.sociology']): return PREVIEW_SOCIOLOGY
    case (COURSE_IDS['preview.collegealgebra']): return PREVIEW_COLLEGE_ALGEBRA
    case (COURSE_IDS['preview.collegewriting-i']): return PREVIEW_COLLEGE_WRITING_I
    case COURSE_IDS['preview.principlesofeconomics']: return PREVIEW_PRINCIPLES_OF_ECONOMICS
    case (COURSE_IDS.financialaccounting): return FINANCIAL_ACCOUNTING
    case (COURSE_IDS['financialaccounting.plus']): return FINANCIAL_ACCOUNTING_PLUS
    case COURSE_IDS.collegesuccess: return COLLEGE_SUCCESS
    case COURSE_IDS.managerialaccounting: return MANAGERIAL_ACCOUNTING
    case (COURSE_IDS['preview.computerscience-i']): return PREVIEW_COMPUTER_SCIENCE_I
    case COURSE_IDS['computerscience-i']: return COMPUTER_SCIENCE_I
    case COURSE_IDS['computerscience-i.plus']: return COMPUTER_SCIENCE_I_PLUS
    case (COURSE_IDS.sociology): return SOCIOLOGY
    case (COURSE_IDS['sociology.plus']): return SOCIOLOGY_PLUS
    case (COURSE_IDS.business): return BUSINESS
    case (COURSE_IDS['business.plus']): return BUSINESS_PLUS
    case COURSE_IDS['preview.business']: return PREVIEW_BUSINESS
    case (COURSE_IDS['collegewriting-i']): return COLLEGE_WRITING_I
    case (COURSE_IDS['collegewriting-i.plus']): return COLLEGE_WRITING_I_PLUS
    case COURSE_IDS['collegewriting-ii']: return COLLEGE_WRITING_II
    case COURSE_IDS['preview.collegewriting-ii']: return PREVIEW_COLLEGE_WRITING_II
    case COURSE_IDS['collegewriting-ii.plus']: return COLLEGE_WRITING_II_PLUS
    case (COURSE_IDS.astronomy): return ASTRONOMY
    case (COURSE_IDS['astronomy.plus']): return ASTRONOMY_PLUS
    case (COURSE_IDS['preview.astronomy']): return PREVIEW_ASTRONOMY
    case (COURSE_IDS.test): return TEST
    case (COURSE_IDS.content): return CONTENT
    case (COURSE_IDS['dataanalytics-i.cert']): return DATAANALYTICS_I_CERT
    case (COURSE_IDS['salesoperations.cert']): return SALES_OPERATIONS_CERT
    case (COURSE_IDS['digitalmarketing-i.cert']): return DIGITAL_MARKETING_I_CERT
    case (COURSE_IDS['digitalmarketing-ii.cert']): return DIGITAL_MARKETING_II_CERT
    case (COURSE_IDS['dataanalytics-ii.cert']): return DATAANALYTICS_II_CERT
    case (COURSE_IDS['projectmanagement.cert']): return GOOGLE_PROJECT_MANAGEMENT_CERT
    case (COURSE_IDS['itsupport.cert']): return IT_SUPPORT_CERT
    case (COURSE_IDS['preview.dataanalytics-i.cert']): return PREVIEW_DATAANALYTICS_I_CERT
    case (COURSE_IDS['preview.itsupport.cert']): return PREVIEW_IT_SUPPORT_CERT
    case (COURSE_IDS['preview.digitalmarketing-i.cert']): return PREVIEW_DIGITAL_MARKETING_I_CERT
    case (COURSE_IDS['preview.projectmanagement.cert']): return PREVIEW_GOOGLE_PROJECT_MANAGEMENT_CERT
    case (COURSE_IDS['preview.salesoperations.cert']): return PREVIEW_SALES_OPERATIONS_CERT
    case (COURSE_IDS['business.ext']): return BUSINESS_EXT
    case (COURSE_IDS['sociology.ext']): return SOCIOLOGY_EXT
    case (COURSE_IDS['principlesofeconomics.ext']): return PRINCIPLES_OF_ECONOMICS_EXT
    case (COURSE_IDS['computerscience-i.ext']): return COMPUTER_SCIENCE_I_EXT
    case (COURSE_IDS['calculus.ext']): return CALCULUS_EXT
    case (COURSE_IDS['psychology.ext']): return PSYCHOLOGY_EXT
    case (COURSE_IDS['statistics.ext']): return STATISTICS_EXT
    case (COURSE_IDS['collegealgebra.ext']): return COLLEGE_ALGEBRA_EXT
    case (COURSE_IDS['precalculus.ext']): return PRECALCULUS_EXT
    case (COURSE_IDS['financialaccounting.ext']): return FINANCIAL_ACCOUNTING_EXT
    case (COURSE_IDS['professionalcommunication.ext']): return PROFESSIONAL_COMMUNICATION_EXT

    default: return CALCULUS
  }
}
