import cloneDeep from 'lodash/cloneDeep'
import findLastIndex from 'lodash/findLastIndex'
import {
  secondsSinceEpoch,
  diffDays,
  weeksToSeconds,
  daysToSeconds,
  changeTimezone,
  dateToSecondsSinceEpoch,
  secondsToFormattedDateTimeShort,
  convertDateToSecondsSinceEpoch,
  getTimezoneShort
} from './dateTimeUtils'
import {
  BEFORE_EXAM_START,
  PRACTICE_EXAM_UUID,
  ASSIGNMENT_PAGE_LAYOUTS
} from '../Constants'
import {
  COURSE_LOCK_DATE,
  PREV_EXAM_DUE_DATE,
  BEFORE_EXAM_START_DATE,
  AFTER_EXAM_DUE_DATE,
  PREV_EXAM_START_DATE,
  NO_ASSESSMENTS,
  STUDENT_STATUS_WITHDRAW,
  PRACTICE_EXAM_AFTER_END_DATE
} from '../Constants/chapterLockCodes'
import {
  SINGLE_MIDTERM_EXAM,
  PRACTICE_EXAM
} from '../Constants/examType'
import config from '../config'
import { WITHDRAW } from '../Constants/studentStatus'
import { ACTIVE_LEARNING, EXAM, QUIZ } from '../Constants/sectionType'
import { emitter } from '../Components/Emitter/Emitter'
import { ON_TRACK_STUDENT_EVENT } from '../Constants/emitterKeys'
import { MULTIPLE } from '../Constants/frequency'
import {
  CHAPTER,
  EXAM as EXAM_CHAPTER,
  CODING_ASSIGNMENT,
  WRITING_ASSIGNMENT,
  REVIEW,
  ORIENTATION
} from '../Constants/chapterType'
import findIndex from 'lodash/findIndex'
import { COPY_SCHEDULE, MIDTERM_EXAM_STARTED } from '../Constants/eventTypes'
import { VIP } from '../Constants/courseNames'

export {
  isLastAssessmentOfPreviousUnit,
  removeFieldFromAllSections,
  removeQuizzesFromSection,
  removeQuizzesFromChapters,
  getChapterUnlockDateBySchedule,
  getAssignmentStatus,
  getQuizNumberUptoPreviousSection,
  getFinalExamDates,
  getExamStartDate,
  isExamUpcoming,
  getChapterExamDates,
  getChapterPrevExam,
  getChapterPrevExamDates,
  getChapterLockCode,
  getLockTextFromLockCode,
  getChapterLockText,
  getChaptersLockText,
  getChapterLockDates,
  getExamRedirectQuestionUUID,
  getVersionQuestions,
  calculateLockDate,
  calculateUnlockDate,
  getLastProgressedChapterUUID,
  getLastProgressedSectionUUID,
  shouldShowDueDate,
  isChapter,
  isOrientationChapter,
  isReviewChapter,
  isChapterAfterExam,
  getPreviousChapter,
  shouldHideChapterNumber,
  shouldHideSectionNumber,
  shouldHideBreadCrumbSectionNumber,
  shouldHideActiveLearningStatus,
  getChapterActiveLearningThemeName,
  getQuizNumber,
  trackStudentEvent,
  practiceExamReleased,
  getCurrentInstructorDetails,
  getSubLectureNumber,
  trackCopyScheduleEvent,
  getNumberFromString,
  isWritingAssignmentComplete,
  getChapterPrevAssignment,
  getChapterCourseUnitTitle,
  addPartsToChapters,
  isPracticeExam,
  getFinalAssignmentDates,
  getPreviousAssessmentLockUnlockDates,
  getWritingAssignmentLockUnlockDates,
  shouldSaveMinimumSectionProgress,
  getExamDatesFromCohortExamDates,
  getExamDatesFromExamRetakes,
  isExamRetakeType,
  isRealExam,
  isMidExam,
  getChapterLockDatesByExamDates
}

function getFinalExamDates (chapters, cohortData, examRetake) {
  const exams = chapters.filter((elem) => elem.type === 'exam')
  const finalExam = exams?.[exams?.length - 1]

  if (config.course.hasFinalExam && finalExam) {
    return getChapterLockDates({
      chapter: finalExam,
      cohortData,
      examRetake,
      isForFinalExam: true
    })
  }

  return getFinalAssignmentDates(cohortData)
}

function getFinalAssignmentDates (cohortData) {
  const { cohortMilestones, cohortExamDates: { courseEndDate } = {} } = cohortData || {}
  const finalAssignment = cohortMilestones?.find(({ finalAssignment }) => finalAssignment)
  if (!finalAssignment) return { lockDate: null, unlockDate: null }

  const { lockDate, unlockDate } = getWritingAssignmentLockUnlockDates(finalAssignment)

  return {
    unlockDate,
    lockDate: getExamEndDateBasedOnExamExtension(courseEndDate, lockDate)
  }
}

function getWritingAssignmentLockUnlockDates (assignment) {
  let lockDate = null
  let unlockDate = null

  if (!assignment) return { lockDate, unlockDate }

  const {
    lockTime,
    unlockTime
  } = assignment

  lockDate = convertDateToSecondsSinceEpoch(lockTime)
  unlockDate = convertDateToSecondsSinceEpoch(unlockTime)

  return { lockDate, unlockDate }
}

function getChapterExamDates ({ chapterIndex, chapters, cohortData, courseId, examRetake }) {
  const subsequentChapters = chapters.slice(chapterIndex + 1)
  const nextExam = subsequentChapters.find(elem => elem.type === 'exam')
  const isSingleMidtermExamCourse = config.isCourseWithSingleMidtermExam(courseId)

  return getChapterLockDates({
    chapter: nextExam,
    cohortData,
    examRetake,
    isForFinalExam: false,
    isSingleMidtermExamCourse
  })
}

function isWritingAssignmentComplete (assignmentProgress, chapterUUID) {
  const chapterProgress = assignmentProgress?.[chapterUUID]

  return chapterProgress?.status === 'submitted'
}

function isLastAssessmentOfPreviousUnit (params) {
  const { assessmentChapter, courseUnits, currentChapter } = params || {}
  if (!currentChapter || !assessmentChapter || !courseUnits?.length) return false

  const currentChapterUnitIndex = courseUnits.findIndex(unit => {
    return unit?.unitContent?.some(
      content => content?.uuid === currentChapter['chapter_uuid']
    )
  })
  const previousUnit = courseUnits[currentChapterUnitIndex - 1]
  const { unitContent } = previousUnit || {}
  const previousUnitLastChapter = unitContent?.[unitContent?.length - 1]
  return previousUnitLastChapter?.uuid === assessmentChapter['chapter_uuid']
}

function getChapterPrevAssignment ({ chapterIndex, chapters, courseUnits }) {
  if (!courseUnits?.length) return null

  const previousChapters = chapters.slice(0, chapterIndex).reverse()
  const previousAssignment = previousChapters.find(chapter => {
    if (chapter.type !== WRITING_ASSIGNMENT) return false

    return isLastAssessmentOfPreviousUnit({
      assessmentChapter: chapter,
      courseUnits,
      currentChapter: chapters?.[chapterIndex]
    })
  })

  return previousAssignment || null
}

function getExamEndDateBasedOnExamExtension (courseEndDate, assignmentEndDate) {
  // If courseEndDate will contain examExtension, so it will be greater than assignmentEndDate
  if (courseEndDate > assignmentEndDate) {
    return courseEndDate
  }
  return assignmentEndDate
}

function isExamRetakeType ({
  cohortId,
  examRetake,
  chapter
}) {
  if (!chapter?.title || !cohortId || !examRetake?.length) return false

  const { course: { examRetakeVersions } } = config
  if (!examRetakeVersions) return false

  const currentRetake = examRetake?.find(retake => retake?.cohort?.id === cohortId)

  const {
    retakeStartDate,
    retakeEndDate
  } = getExamDatesFromExamRetakes({
    exam: chapter,
    examRetake: currentRetake
  })

  return !!(retakeStartDate && retakeEndDate)
}

function getExamRedirectQuestionUUID ({
  examRetake,
  cohortId,
  chapter,
  examVersions
}) {
  const isRetake = isExamRetakeType({
    examRetake,
    cohortId,
    chapter
  })
  const version = isRetake && examVersions?.length
  if (!version) return null

  const versionQuestionUUID = examVersions[0].Question[0].Question_uuid
  return versionQuestionUUID
}

function getVersionQuestions ({
  cohortId,
  chapter,
  examRetake
}) {
  const isAllowed = isExamRetakeType({
    examRetake,
    chapter,
    cohortId
  })
  if (!isAllowed) return

  const { examVersions } = chapter
  if (!examVersions?.length) return null

  const versionUUID = chapter.examVersions[0].uuid
  const questions = chapter.examVersions[0].Question
    .map(question => { return { ...question } })
  return { Question: questions, versionUUID: versionUUID }
}
function getChapterLockDatesByExamDates ({
  cohortId,
  examRetake,
  chapter,
  cohortExamDates,
  isForFinalExam,
  isSingleMidtermExamCourse
}) {
  const { title } = chapter || {}
  let unlockDate = null
  let lockDate = null

  const isRetakeAllowed = isExamRetakeType({ examRetake, chapter, cohortId })
  if (isRetakeAllowed) {
    const retake = examRetake?.find(retake => retake?.cohort?.id === cohortId)

    const {
      retakeStartDate,
      retakeEndDate
    } = getExamDatesFromExamRetakes({
      exam: chapter,
      examRetake: retake
    })

    if (retakeStartDate && retakeEndDate) {
      unlockDate = retakeStartDate
      lockDate = retakeEndDate
    }

    return {
      unlockDate,
      lockDate
    }
  }

  if (!cohortExamDates) {
    return {
      unlockDate,
      lockDate
    }
  }

  let examStartDate = null
  let examEndDate = null

  const {
    assignmentStartDate,
    assignmentEndDate,
    courseEndDate
  } = cohortExamDates

  if (title === SINGLE_MIDTERM_EXAM && isForFinalExam && assignmentStartDate && assignmentEndDate) {
    examStartDate = assignmentStartDate
    examEndDate = getExamEndDateBasedOnExamExtension(courseEndDate, assignmentEndDate)
  } else if (!title && !isForFinalExam && isSingleMidtermExamCourse && assignmentStartDate && assignmentEndDate) {
    examStartDate = assignmentStartDate
    examEndDate = getExamEndDateBasedOnExamExtension(courseEndDate, assignmentEndDate)
  } else {
    const { startDate, endDate } = getExamDatesFromCohortExamDates({
      exam: chapter,
      cohortExamDates
    })

    examStartDate = startDate
    examEndDate = endDate
  }

  if (examStartDate && examEndDate) {
    unlockDate = examStartDate
    lockDate = examEndDate
  }

  return {
    unlockDate,
    lockDate
  }
}

// Returns the unlock and and lock dates for the given chapter, based on the
// cohort start date. Will return the old style fixed dates if the relative
// date propertiers are not available.
function getChapterLockDates ({
  chapter,
  cohortData,
  examRetake,
  isForFinalExam,
  isSingleMidtermExamCourse
}) {
  let unlockDate = null
  let lockDate = null

  if (!chapter && !isSingleMidtermExamCourse) {
    return {
      unlockDate,
      lockDate
    }
  }

  const {
    cohortID,
    cohortStartDate,
    cohortModifier,
    cohortSpecialDays,
    cohortExamDates
  } = cohortData

  const chapterLockDates = getChapterLockDatesByExamDates({
    chapter,
    cohortId: cohortID,
    examRetake,
    cohortExamDates,
    isForFinalExam,
    isSingleMidtermExamCourse
  })

  if (chapterLockDates.unlockDate && chapterLockDates.lockDate) {
    unlockDate = chapterLockDates.unlockDate
    lockDate = chapterLockDates.lockDate
  } else if (chapter && Object.prototype.hasOwnProperty.call(chapter, 'unlock_at_week') &&
  Object.prototype.hasOwnProperty.call(chapter, 'unlock_duration')) {
  // Use the new relative dates if they are available. Otherwise, use the
    // old style fixed dates.
    let {
      unlock_at_week: unlockAtWeek,
      unlock_duration: unlockDuration
    } = chapter
    if (cohortModifier) {
      unlockAtWeek = cohortModifier * unlockAtWeek
      if (unlockDuration > 0) unlockDuration = cohortModifier * unlockDuration
    }
    let unlockAfterDays = (unlockAtWeek - 1) * 7
    if (cohortSpecialDays && cohortSpecialDays.length) {
      cohortSpecialDays.forEach(({ startDate, endDate, specialDays }) => {
        if (specialDays && unlockAfterDays >= specialDays) {
          unlockAfterDays = unlockAfterDays + diffDays(startDate, endDate)
        }
      })
    }
    unlockDate = dateToSecondsSinceEpoch(
      calculateUnlockDate(unlockAfterDays, cohortStartDate * 1000)
    )
    // Unlock duration of -1 means it never re-locks and stays unlocked forever.
    if (unlockDuration === -1) {
      lockDate = null
    } else {
      lockDate = dateToSecondsSinceEpoch(
        calculateLockDate(
          unlockAfterDays,
          unlockDuration,
          cohortStartDate * 1000
        )
      )
    }
  }

  return {
    unlockDate,
    lockDate
  }
}

function calculateLockDate (unlockAfterDays, unlockDuration, cohortStartDate) {
  const unlockDatePST = calculateUnlockDate(unlockAfterDays, cohortStartDate)

  // Lock duration of zero means never unlock, so we make the unlock and lock
  // dates the same.
  if (unlockDuration === 0) return unlockDatePST

  // Calculate the relative lock date
  const seconds = weeksToSeconds(unlockDuration)
  unlockDatePST.setTime(unlockDatePST.getTime() + (seconds * 1000))

  // Convert date to UTC with time set to 12pm
  const lockDateUTC = new Date(unlockDatePST.getUTCFullYear(), unlockDatePST.getUTCMonth(), unlockDatePST.getUTCDate(), 12, 0)

  // Subtract 1 minute so we land on 11:59am
  lockDateUTC.setTime(lockDateUTC.getTime() - (60 * 1000))

  // Convert UTC back to PST
  const lockDatePST = changeTimezone(lockDateUTC, 'America/Los_Angeles')

  return lockDatePST
}

function calculateUnlockDate (unlockAfterDays, cohortStartDate) {
  // Sets the time to 1:01am to account for daylight savings. Without doing
  // this when we calculate the number of weeks it might end up landing
  // at 11am. This ensures that if we lose an hour due to daylight
  // savings it will be the same date, but at 12:01pm.
  const date = new Date(cohortStartDate)
  date.setUTCHours(1, 1)

  // Convert date to PST time.
  const datePST = changeTimezone(date, 'America/Los_Angeles')

  // Calculate the relative unlock date
  const seconds = daysToSeconds(unlockAfterDays)
  datePST.setTime(datePST.getTime() + (seconds * 1000))

  // Convert date to UTC with time set to 09:00am
  const unlockDateUTC = new Date(datePST.getUTCFullYear(), datePST.getUTCMonth(), datePST.getUTCDate(), 9, 0)

  // Convert UTC back to PST
  const unlockDatePST = changeTimezone(unlockDateUTC, 'America/Los_Angeles')

  return unlockDatePST
}

function getChapterPrevExamDates ({ chapterIndex, chapters, cohortData, examRetake }) {
  const previousChapters = chapters.slice(0, chapterIndex).reverse()
  const previousExam = previousChapters.find(elem => elem.type === 'exam')

  // If the chapter type is an exam then the previous dates are not defined
  if (chapters[chapterIndex].type === 'exam') {
    return {
      unlockDate: undefined,
      lockDate: undefined
    }
  }

  return getChapterLockDates({ chapter: previousExam, cohortData, examRetake })
}

function getChapterPrevExam ({ chapterIndex, chapters, courseUnits }) {
  if (!chapters?.length) return null

  const previousChapters = chapters.slice(0, chapterIndex).reverse()
  if (!courseUnits?.length) {
    return previousChapters?.find(elem => elem.type === EXAM_CHAPTER)
  }

  const previousExam = previousChapters.find(chapter => {
    if (chapter.type !== EXAM_CHAPTER) return false

    return isLastAssessmentOfPreviousUnit({
      assessmentChapter: chapter,
      courseUnits,
      currentChapter: chapters?.[chapterIndex]
    })
  })

  return previousExam || null
}

function getChapterUnlockDateBySchedule ({ schedule, chapter } = {}) {
  const chapterSchedule = schedule?.find(currentSchedule =>
    currentSchedule?.materialCovered?.includes(
      chapter?.sections?.[0]?.title
    )
  )
  return chapterSchedule?.startDateInSecs || null
}

function getChapterLockCode (chapterIndex, opts) {
  const { isAdmin, cohortName } = opts
  const isVipCohort = cohortName && cohortName.includes(VIP)
  if (isAdmin === true && isVipCohort) return false
  const {
    examRetake,
    chapters,
    courseUnits,
    cohortModifier,
    cohortMilestones,
    assignmentsProgress,
    cohortSpecialDays,
    examsCompleted,
    cohortExamDates,
    isAuditor,
    isVIP,
    isCohortEndedForStudent,
    isVIPGradedContent,
    isPreviewCourse,
    isStudioCohort,
    cohortID,
    studentStatus,
    auditContentLock,
    courseResourcesSchedule,
    isNoAssessments
  } = opts

  const chapter = chapters?.[chapterIndex]
  const chapterType = chapter?.type
  const isOrientation = isOrientationChapter(chapter)

  const isAuditorAndNotAuditContentLock = isAuditor && !auditContentLock
  const isStudioCohortAndNotExamChapter =
    isStudioCohort && chapterType !== EXAM_CHAPTER

  if (
    isAuditorAndNotAuditContentLock ||
    isStudioCohortAndNotExamChapter ||
    isPreviewCourse ||
    isOrientation ||
    isVIPGradedContent
  ) return false

  const currentDate = convertDateStrToSeconds(opts.currentDate)
  const courseUnlockDate = convertDateStrToSeconds(opts.courseUnlockDate)
  const cohortData = {
    cohortID,
    cohortStartDate: courseUnlockDate,
    cohortModifier,
    cohortSpecialDays,
    cohortExamDates
  }

  if (studentStatus === WITHDRAW && opts.chapters[chapterIndex].type === 'exam') {
    return STUDENT_STATUS_WITHDRAW
  }

  // If isNoAssessments is set, user will not be able to access exams.
  // This message is displayed even if course has not yet started.
  if (isNoAssessments && opts.chapters[chapterIndex].type === 'exam') {
    return NO_ASSESSMENTS
  }

  const isAssignment = [WRITING_ASSIGNMENT, CODING_ASSIGNMENT]
    .includes(opts.chapters[chapterIndex].type)
  if (isVIP && isAssignment) return false

  // Everything is locked until the course unlock date is reached.
  if (currentDate < courseUnlockDate && !isAssignment) {
    return COURSE_LOCK_DATE
  }

  // Logic for chapters
  if (opts.chapters[chapterIndex].type === 'chapter') {
    // All chapters should be unlocked for VIP accounts
    if (isVIP) return false

    const chapterUnlockDate = getChapterUnlockDateBySchedule({
      schedule: courseResourcesSchedule,
      chapter
    })

    if (chapterUnlockDate && currentDate >= chapterUnlockDate) {
      return false
    }

    const {
      unlockDate = null, lockDate = null, isCompleted
    } = getPreviousAssessmentLockUnlockDates({
      examRetake,
      chapterIndex,
      chapters,
      courseUnits,
      cohortData,
      cohortMilestones,
      assignmentsProgress,
      examsCompleted
    })

    // Unlock the chapter if there is no previous exam or assignment.
    if ((unlockDate === null) && (lockDate === null)) return false

    // Unlock the chapter if the previous exam never relocks and the current
    // date is greater than or equal to the previous exam start data.
    if ((lockDate === null)) {
      if (currentDate >= unlockDate) {
        return false
      } else {
        return PREV_EXAM_START_DATE
      }
    }

    // Unlock the chapter if the previous exam or assignment is completed or the current date
    // is greater than or equal to the exam due date of the previous
    // exam or assignment, otherwise lock it.
    const isAssessmentLocked = currentDate >= lockDate
    const isAssessmentUnlocked = currentDate >= unlockDate
    const { course: { unlockChapterOnAssessmentLock } } = config

    if (
      isCompleted ||
      (unlockChapterOnAssessmentLock ? isAssessmentLocked : isAssessmentUnlocked)
    ) {
      return false
    } else {
      return PREV_EXAM_DUE_DATE
    }
  }

  // Review chapter
  if (opts.chapters[chapterIndex].type === REVIEW) {
    const previousChapters = opts.chapters.slice(0, chapterIndex)
    const immediatePreviousChapterIndex = findLastIndex(
      previousChapters,
      chapter => chapter.type === CHAPTER
    )

    if (immediatePreviousChapterIndex === -1) return false

    return getChapterLockCode(
      immediatePreviousChapterIndex, opts
    )
  }

  // Logic for practice exam
  const { title } = chapters[chapterIndex]
  if (title === PRACTICE_EXAM) {
    const exams = chapters.filter((elem) => elem.type === 'exam')
    const finalExam = exams[exams.length - 1]
    const { lockDate } = getChapterLockDates({
      chapter: finalExam,
      examRetake,
      cohortData
    })
    if (currentDate >= lockDate) {
      return PRACTICE_EXAM_AFTER_END_DATE
    }
    return false
  }

  // Logic for exams
  if (opts.chapters[chapterIndex].type === 'exam') {
    // Calculate date conditions.

    const { unlockDate, lockDate } = getChapterLockDates({
      chapter: opts.chapters[chapterIndex],
      examRetake,
      cohortData
    })

    const isStarted = currentDate >= unlockDate
    // If the lock date is null, it means the exam never relocks.
    const isOverDue = (lockDate !== null) && (currentDate >= lockDate)

    if (!isStarted) {
      return BEFORE_EXAM_START_DATE
    }

    if (isOverDue) {
      const { chapter_uuid: chapterUUID } = opts.chapters[chapterIndex]
      const isExamCompleted = chapterUUID in examsCompleted

      const shouldLock = !isExamCompleted || isCohortEndedForStudent
      if (shouldLock) return AFTER_EXAM_DUE_DATE

      return false
    }
    return false
  }
}

function getPreviousAssessmentLockUnlockDates (opts) {
  const {
    examRetake,
    chapterIndex,
    chapters,
    courseUnits,
    cohortData,
    assignmentsProgress,
    cohortMilestones,
    examsCompleted
  } = opts

  // Get the lock date of the previous exam.
  const previousExam = getChapterPrevExam({
    chapterIndex, chapters, courseUnits
  })
  if (previousExam) {
    const { unlockDate, lockDate } = getChapterLockDates({
      chapter: previousExam,
      examRetake,
      cohortData
    })
    const { chapter_uuid: chapterUuid } = previousExam
    const isCompleted = chapterUuid in examsCompleted

    return { unlockDate, lockDate, isCompleted }
  }

  const previousAssignment = getChapterPrevAssignment({
    chapterIndex, chapters, courseUnits
  }) || {}
  const milestone = cohortMilestones?.find(
    milestone => milestone.datoAssignmentUUID === previousAssignment.chapter_uuid
  )

  if (!milestone) return { lockDate: null, unlockDate: null, isCompleted: false }

  const { unlockDate, lockDate } = getWritingAssignmentLockUnlockDates(milestone)

  const isCompleted = isWritingAssignmentComplete(
    assignmentsProgress,
    previousAssignment.chapter_uuid
  )

  return { unlockDate, lockDate, isCompleted }
}

function getChaptersLockText (opts) {
  const {
    chapters
  } = opts

  return chapters.reduce((map, chapter, index) => {
    const { chapter_uuid: chapterUUID } = chapter
    map[chapterUUID] = getChapterLockText(index, opts)
    return map
  }, {})
}

function getChapterLockText (chapterIndex, opts) {
  const {
    examRetake,
    cohortID,
    chapters,
    isVIP,
    courseUnlockDate,
    cohortModifier,
    cohortSpecialDays,
    cohortExamDates
  } = opts

  const lockCode = getChapterLockCode(chapterIndex, opts)

  const cohortData = {
    cohortID,
    cohortStartDate: courseUnlockDate,
    cohortModifier,
    cohortSpecialDays,
    cohortExamDates
  }

  return getLockTextFromLockCode({
    examRetake,
    lockCode,
    chapterIndex,
    chapters,
    cohortData,
    isVIP
  })
}

function getLockTextFromLockCode ({
  examRetake,
  lockCode,
  chapterIndex,
  chapters,
  cohortData,
  isVIP
}) {
  const chapter = chapters[chapterIndex]
  if (!lockCode) return false

  if (lockCode === PREV_EXAM_DUE_DATE) {
    const { lockDate } = getChapterPrevExamDates({
      chapterIndex, chapters, cohortData, examRetake
    })
    const textDateTime = secondsToFormattedDateTimeShort(lockDate)
    const timezone = getTimezoneShort(lockDate)

    return `Will unlock on ${textDateTime} ${timezone}`
  }

  if (lockCode === PREV_EXAM_START_DATE) {
    const { unlockDate } = getChapterPrevExamDates({
      chapterIndex, chapters, cohortData, examRetake
    })
    const textDateTime = secondsToFormattedDateTimeShort(unlockDate)
    const timezone = getTimezoneShort(unlockDate)

    return `Will unlock on ${textDateTime} ${timezone}`
  }

  if (lockCode === BEFORE_EXAM_START_DATE ||
    (lockCode === COURSE_LOCK_DATE && chapter.type === 'exam')) {
    const { unlockDate } = getChapterLockDates({ chapter, cohortData, examRetake })
    const unlockTextDateTime = secondsToFormattedDateTimeShort(unlockDate, 'short')
    const timezone = getTimezoneShort(unlockDate)

    return `${BEFORE_EXAM_START} ${unlockTextDateTime} ${timezone}`
  }

  if (lockCode === AFTER_EXAM_DUE_DATE) {
    return 'Score: 0%'
  }

  if (lockCode === COURSE_LOCK_DATE) {
    const { cohortStartDate } = cohortData
    const textDateTime = secondsToFormattedDateTimeShort(cohortStartDate)
    const timezone = getTimezoneShort(cohortStartDate)
    const { course: { use0BasedIndexing: isComputerScience } } = config
    return [`Course starts on ${textDateTime} ${timezone}`,
      `Section ${isComputerScience ? '0.0' : '1.1'} is available now. The rest of the course opens ${textDateTime} ${timezone}`]
  }

  if (lockCode === NO_ASSESSMENTS) {
    if (isVIP) return 'Assessment unavailable due to VIP account'
    return 'Assessment unavailable due to enrollment status'
  }

  if (lockCode === PRACTICE_EXAM_AFTER_END_DATE) {
    return 'Exam is closed due to course ends'
  }

  if (lockCode === STUDENT_STATUS_WITHDRAW) {
    return 'Exam is closed because the student is withdrawn'
  }
}

const convertDateStrToSeconds = (dateString) => {
  if (typeof dateString === 'number') return dateString

  return parseInt(dateString, 10)
}

/**
 * getLastProgressedChapterUUID
 * @param  {array} chapters Array of chapters object
 * @param  {object} sectionProgress Student Progress in each sections
 * @return {string} returns the UUID of last chapter student was working on. If the student just started the course it returns the UUID of chapter 1.
 *           Function returns chapter 1 if the student just entered the course.
 */
function getLastProgressedChapterUUID (chapters, sectionProgress) {
  const sectionProgressArray = Object.keys(sectionProgress)
  // if student has no previous progress, return chapter 1
  if (sectionProgressArray.length === 0) {
    const firstChapter = chapters[0]
    return firstChapter && firstChapter.chapter_uuid
  }
  // Filter by type of 'chapter' so exams are not included.
  // Reverse the array order so the first chapter we find is the last one with progress.
  const chapterArray = chapters.filter(e => e.type === 'chapter' && e.chapter_uuid).reverse()
  const lastProgressedChapter = chapterArray.find(chapter => {
    const sections = chapter.sections.map(section => section.section_uuid)
    return sectionProgressArray.some(sectionProgress => sections.includes(sectionProgress))
  })
  return lastProgressedChapter && lastProgressedChapter.chapter_uuid
}

/**
 * getLastProgressedSectionUUID
 * @param  {object} sectionProgress Student Progress in sections
 * @param  {array} sectionArray String array of sections ids for a particular chapter
 * @return {string} returns the latest progressed section UUID of a particular chapter.
 * return undefined if no last progressed section.
 */
function getLastProgressedSectionUUID (sectionProgress, sections) {
  const sectionArray = sections.map(e => e.section_uuid)
  return Object.keys(sectionProgress).filter(e => sectionArray.includes(e)).slice(-1)[0]
}

function shouldShowDueDate (lockDate, totalPer, currentDate) {
  return lockDate > currentDate && totalPer !== 100
}

/**
 * @param {number} midTerm1ExamUnlockDate mid term1 unlock date in seconds
 * @returns {boolean} is current date one week before mid term1 unlock date
 */
function practiceExamReleased (midTerm1ExamUnlockDate) {
  if (!midTerm1ExamUnlockDate) return false
  const NUMBER_OF_WEEKS = 1
  const midTerm1Date = new Date(midTerm1ExamUnlockDate * 1000)
  midTerm1Date.setDate(midTerm1Date.getDate() - NUMBER_OF_WEEKS * 7)
  return dateToSecondsSinceEpoch(midTerm1Date)
}

function getQuizNumber (completeScreenData, childIndex) {
  const {
    course_quiz_number: courseQuizNumber,
    section_quiz_number: sectionQuizNumber,
    breadcrumbArr,
    breadcrumb,
    type
  } = completeScreenData.children[childIndex]

  if (courseQuizNumber !== undefined || sectionQuizNumber !== undefined) {
    return { courseQuizNumber, sectionQuizNumber }
  }

  let title = ''

  switch (type) {
    // Completion Page - Guesswork, Active Learning, Quiz
    case 'end_content_intertitial_section':
      title = breadcrumb
      break
    // Landing Page - Guesswork, Active Learning, Practice Exercise, Quiz
    case 'intertitial':
      title = breadcrumb
      break
    default:
      title = breadcrumbArr[0][0]
  }
  const quizNumber = title.replace(/[^-.0-9]/g, '')
  return parseInt(quizNumber, 10)
}

function trackStudentEvent ({
  questionType,
  completeScreenData,
  cohort,
  duration,
  isPracticeExamLastQuestion,
  activeChildrenIndex,
  themeName
}) {
  const examType = [QUIZ, EXAM, ACTIVE_LEARNING]
  if (!examType.includes(questionType)) return

  const breadcrumbArr = completeScreenData.breadcrumbArr
  const isFinalExam = completeScreenData?.chapter?.isFinalExam
  const isPracticeExam = breadcrumbArr[1][0] === PRACTICE_EXAM
  let eventData

  switch (questionType) {
    case EXAM:
      eventData = {
        properties: {
          course: breadcrumbArr[0][0],
          cohort,
          cohort_length: duration,
          time_stamp: new Date().getTime(),
          ...!(isFinalExam || isPracticeExam) &&
                   { midterm_number: getNumberFromString(breadcrumbArr[1][0]) }
        },
        event: (isFinalExam || isPracticeExam)
          ? `${breadcrumbArr[1][0]} ${isPracticeExamLastQuestion ? 'Completed' : 'Started'}`
          : MIDTERM_EXAM_STARTED,
        frequency: MULTIPLE
      }
      break

    case ACTIVE_LEARNING:
      eventData = {
        properties: {
          course: breadcrumbArr[0][0],
          cohort,
          cohort_length: duration,
          sub_chapter: breadcrumbArr[2][0],
          chapter: breadcrumbArr[1][0],
          time_stamp: new Date().getTime(),
          ...(themeName) && { theme_name: themeName }
        },
        event: `${questionType} Started`,
        frequency: MULTIPLE
      }
      break

    default:
      const {
        courseQuizNumber,
        sectionQuizNumber
      } = getQuizNumber(completeScreenData, activeChildrenIndex)
      eventData = {
        properties: {
          course: breadcrumbArr[0][0],
          cohort,
          cohort_length: duration,
          sub_chapter: breadcrumbArr[2][0],
          chapter: breadcrumbArr[1][0],
          course_quiz_number: courseQuizNumber,
          section_quiz_number: sectionQuizNumber,
          time_stamp: new Date().getTime()
        },
        event: `${questionType} Started`,
        frequency: MULTIPLE
      }
      break
  }
  emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
}

function trackCopyScheduleEvent ({ courseName, cohort, pageName }) {
  const eventData = {
    properties: {
      course_name: courseName,
      cohort,
      page_name: pageName,
      time_stamp: new Date().getTime()
    },
    event: COPY_SCHEDULE,
    frequency: MULTIPLE
  }
  emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
}

/**
 * @param {Object} chapter chapter object including type
 * @returns {boolean} is chapter either regular or orientation type
 */
function isChapter (chapter) {
  return chapter.type === CHAPTER || isOrientationChapter(chapter) || isReviewChapter(chapter)
}

/**
 * @param {Object)} chapter chapter object
 * @returns {boolean} is chapter orientation type
 */
function isOrientationChapter (chapter) {
  if (!chapter) return false
  return chapter.type === ORIENTATION
}

function isReviewChapter (chapter) {
  if (!chapter) return false
  return chapter.type === REVIEW
}

function shouldSaveMinimumSectionProgress (chapter) {
  return !isOrientationChapter(chapter) && !isReviewChapter(chapter)
}

function isSpecialTopicChapter (chapter) {
  return chapter.title.includes('Special Topic')
}

function shouldHideChapterNumber (chapter) {
  return isOrientationChapter(chapter) || config.course.hideChapterNumber ||
    isSpecialTopicChapter(chapter)
}

function shouldHideSectionNumber (chapter) {
  return isOrientationChapter(chapter) || config.course.hideChapterNumber ||
    isSpecialTopicChapter(chapter) || isReviewChapter(chapter)
}

function shouldHideBreadCrumbSectionNumber (chapter) {
  return isOrientationChapter(chapter) || isReviewChapter(chapter)
}

function shouldHideActiveLearningStatus (chapter) {
  return isOrientationChapter(chapter) || isReviewChapter(chapter)
}

function getChapterActiveLearningThemeName (chapter, themeName) {
  if (isOrientationChapter(chapter)) return 'Orientation'
  if (isReviewChapter(chapter)) return 'Review'
  if (!themeName) return ''

  return `${themeName} Theme`
}

function isChapterAfterExam (chapter, chapters) {
  if (!chapters || !Array.isArray(chapters)) return false

  const filteredChapters = chapters.filter(chapter => [CHAPTER, EXAM_CHAPTER].includes(chapter?.type))
  const chapterIndex = filteredChapters.indexOf(chapter)
  if (chapterIndex <= 0) return false

  const previousChapter = filteredChapters[chapterIndex - 1]
  return (previousChapter?.type === EXAM_CHAPTER)
}

function getPreviousChapter (chapter, chapters) {
  if (!chapters || !Array.isArray(chapters)) return null

  const filteredChapters = chapters.filter(chapter => chapter?.type === CHAPTER)
  const chapterIndex = filteredChapters.indexOf(chapter)
  if (chapterIndex <= 0) return null

  return filteredChapters[chapterIndex - 1]
}

function getCurrentInstructorDetails ({ currentInstructorID, lectureVideos }) {
  const { instructor } = lectureVideos
    .find(({ instructor: { instructor_uuid: InstructorUuid } }) =>
      InstructorUuid === currentInstructorID) || {}

  return instructor
}

function getSubLectureNumber ({ multipleVideos, lectureVideos }) {
  const { course: { isCalculus } } = config
  if (isCalculus) return null

  const index = findIndex(multipleVideos, (lecture) => {
    return lecture.id === lectureVideos[0].lecturevideos_uuid
  })

  return index + 1
}

function getNumberFromString (string) {
  if (!string) return null

  const number = string.replace(/[^-.0-9]/g, '')
  return parseInt(number, 10)
}

function getExamStartDate (cohortExamDates, chapter) {
  if (!cohortExamDates) return {}

  const {
    startDate,
    isExamUpcoming
  } = getExamDatesFromCohortExamDates({
    exam: chapter,
    cohortExamDates
  })

  if (!startDate) return {}

  return {
    examStartDate: startDate,
    isUpcoming: isExamUpcoming
  }
}

function isExamUpcoming (startTime) {
  if (!startTime) return
  return secondsSinceEpoch() < startTime
}

function addPartsToChapters (chapters, courseUnits) {
  const newChapters = []
  const addedParts = []

  chapters.forEach(chapter => {
    const { chapter_uuid: chapterUUID } = chapter

    const chapterCourseUnitTitle = getChapterCourseUnitTitle(chapterUUID, courseUnits)
    if (!chapterCourseUnitTitle) {
      newChapters.push(chapter)
      return
    }

    const isTitleAdded = addedParts.includes(chapterCourseUnitTitle)
    if (isTitleAdded) {
      newChapters.push(chapter)
      return
    }

    newChapters.push(getPartChapter(chapterCourseUnitTitle))
    newChapters.push(chapter)

    addedParts.push(chapterCourseUnitTitle)
  })

  return newChapters
}

function getPartChapter (title) {
  return {
    type: 'part',
    title
  }
}

function getChapterCourseUnitTitle (chapterUUID, courseUnits) {
  if (!courseUnits || !courseUnits.length) return null

  const unit = courseUnits.find(courseUnit => {
    const { unitContent } = courseUnit

    return !!unitContent.find(unitChapter => unitChapter.uuid === chapterUUID)
  })

  return unit?.title || ''
}

function isPracticeExam (chapterUUID) {
  return chapterUUID === PRACTICE_EXAM_UUID
}

function getQuizNumberUptoPreviousSection ({
  courseMetadata,
  currentSectionUUID
}) {
  const { chapters: courseChapters = [] } = courseMetadata || {}
  const chapters = cloneDeep(courseChapters)
  const quizzesCount = chapters
    .reduce((chapterQuizCount, chapter, chapterIndex, chapterArray) => {
      const { sections = [] } = chapter
      const sectionQuizzesCount = sections
        .reduce((sectionQuizCount, section, sectionIndex, sectionArray) => {
          const { quizCount = 0, section_uuid: sectionUUID } = section
          if (currentSectionUUID === sectionUUID) {
            sectionArray.splice(sectionIndex)
            chapterArray.splice(chapterIndex)
            return sectionQuizCount
          }
          return sectionQuizCount + quizCount
        }, 0)
      return chapterQuizCount + sectionQuizzesCount
    }, 0)

  return quizzesCount
}

function getAssignmentStatus ({
  assignment,
  isCodingAssignment,
  lockTime,
  unlockTime,
  status
}) {
  const { SUBMIT, RESUBMIT, REVIEW, PREVIEW, GRADE_PENDING } = ASSIGNMENT_PAGE_LAYOUTS
  const currentTime = secondsSinceEpoch()
  const unlockTimeInSeconds = dateToSecondsSinceEpoch(new Date(unlockTime))

  if (currentTime < unlockTimeInSeconds) return PREVIEW

  const lockTimeInSeconds = dateToSecondsSinceEpoch(new Date(lockTime))
  const twoWeeksAfterLockTime = lockTimeInSeconds + weeksToSeconds(2)
  const isSubmitted = status === 'submitted'

  if (isCodingAssignment) {
    if (currentTime < lockTimeInSeconds) return isSubmitted ? GRADE_PENDING : SUBMIT
    if (currentTime < twoWeeksAfterLockTime) return GRADE_PENDING
    return REVIEW
  }

  if (currentTime < lockTimeInSeconds) {
    // To handle when assignment status is marked submitted, but
    // the writing assignment file/content cannot be retrieved
    if (isSubmitted) {
      return assignment
        ? RESUBMIT
        : SUBMIT
    }
    return SUBMIT
  }

  return REVIEW
}

function isRealExam (chapter) {
  return chapter?.type === 'exam' && chapter?.title !== PRACTICE_EXAM
}

function isMidExam (chapter) {
  return isRealExam(chapter) && !chapter?.isFinalExam
}

function getExamDatesFromCohortExamDates ({
  exam = {},
  cohortExamDates
}) {
  const {
    midTerm1StartDate,
    midTerm1EndDate,
    midTerm2StartDate,
    midTerm2EndDate,
    finalExamStartDate,
    courseEndDate
  } = cohortExamDates || {}

  if (!isRealExam(exam)) {
    return {
      startDate: null,
      endDate: null
    }
  }

  const { examNumber, isFinalExam } = exam

  if (isFinalExam) {
    return {
      startDate: finalExamStartDate,
      endDate: courseEndDate,
      isExamUpcoming: isExamUpcoming(finalExamStartDate)
    }
  }

  // Existing Midterm 1
  if (examNumber === 1) {
    return {
      startDate: midTerm1StartDate,
      endDate: midTerm1EndDate,
      isExamUpcoming: isExamUpcoming(midTerm1StartDate)
    }
  }

  // Existing Midterm 2
  if (examNumber === 2) {
    return {
      startDate: midTerm2StartDate,
      endDate: midTerm2EndDate,
      isExamUpcoming: isExamUpcoming(midTerm2StartDate)
    }
  }

  // Other exams template will be: exam{examNumber}StartDate and exam{examNumber}EndDate
  // Example: exam3StartDate and exam3EndDate
  const examStartDateKey = `exam${examNumber}StartDate`
  const examEndDateKey = `exam${examNumber}EndDate`

  return {
    startDate: cohortExamDates?.[examStartDateKey],
    endDate: cohortExamDates?.[examEndDateKey],
    isExamUpcoming: isExamUpcoming(cohortExamDates?.[examStartDateKey])
  }
}

function getExamDatesFromExamRetakes ({ exam, examRetake }) {
  const {
    finalRetakeStart,
    finalDeadline,
    midterm1RetakeStart,
    midterm1Deadline,
    midterm2Deadline,
    midterm2RetakeStart
  } = examRetake || {}

  const { examNumber, isFinalExam } = exam || {}

  if (isFinalExam) {
    return {
      retakeStartDate: finalRetakeStart,
      retakeEndDate: finalDeadline,
      isExamUpcoming: isExamUpcoming(finalRetakeStart)
    }
  }

  // Existing Midterm 1
  if (examNumber === 1) {
    return {
      retakeStartDate: midterm1RetakeStart,
      retakeEndDate: midterm1Deadline,
      isExamUpcoming: isExamUpcoming(midterm1RetakeStart)
    }
  }

  // Existing Midterm 2
  if (examNumber === 2) {
    return {
      retakeStartDate: midterm2RetakeStart,
      retakeEndDate: midterm2Deadline,
      isExamUpcoming: isExamUpcoming(midterm2RetakeStart)
    }
  }

  // Other exams template will be: exam{examNumber}RetakeStart and exam{examNumber}RetakeDeadline
  // Example: exam3RetakeStart and exam3RetakeDeadline
  const examStartDateKey = `exam${examNumber}RetakeStart`
  const examEndDateKey = `exam${examNumber}RetakeDeadline`

  return {
    retakeStartDate: examRetake?.[examStartDateKey],
    retakeEndDate: examRetake?.[examEndDateKey],
    isExamUpcoming: isExamUpcoming(examRetake?.[examStartDateKey])
  }
}

function removeQuizzesFromSection (data) {
  if (!data?.['section_exe']?.quiz) return data

  const sectionData = cloneDeep(data)
  delete sectionData['section_exe'].quiz
  return sectionData
}

function removeQuizzesFromChapters (data, fieldName = 'quizUUIDs') {
  if (!data?.chapters?.length) return data

  const newCourseData = {
    ...data,
    chapters: removeFieldFromAllSections(data.chapters, fieldName)
  }

  return newCourseData
}

function removeFieldFromAllSections (data, fieldName = 'quizUUIDs') {
  if (!data?.length) return data

  const chapters = cloneDeep(data)
  const chaptersWithoutQuiz = chapters.map(chapter => {
    if (!chapter?.sections?.length) return chapter

    return {
      ...chapter,
      sections: chapter.sections.map(section => {
        delete section[fieldName]
        return section
      })
    }
  })

  return chaptersWithoutQuiz
}
