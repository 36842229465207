import styled, { createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const OverrideStyles = createGlobalStyle`
  .Page-layout-main-wrapper {
    padding-bottom: 0px!important;
  }
`

export const PageWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 112.95px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  & > div {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    ${mediaqueries.mobile`
      padding-left: 24px;
      padding-right: 24px;
      align-items: flex-start;
    `}

    &.content {
      & > p {
        &.description {
          font-size: 16px;
          font-weight: 300;
          line-height: 32px;
          margin-bottom: 16px;
        }
      }
    }
  }

  ${mediaqueries.mobile`
    height: calc(100vh - 133.95px);
  `}
`
export const LockIcon = styled.img`
  height: 48px;
  width: 48px;
  margin-bottom: 16px;
`
export const Title = styled.h1`
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
  margin-bottom: 16px;
`

export const KeysInputWrapper = styled.div`
  width: 405px;
  ${mediaqueries.mobile`
    width: 100%;
  `}
`

export const Button = styled.button`
  width: 160px;
  height: 48px;
  padding: 16px 24px;
  color: #161618;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 5px;
  background: #5FC4B8;
  border: none!important;
  outline: none!important;
  margin-top: 16px;

  ${mediaqueries.mobile`
    width: 100%;
  `}

  :disabled {
    opacity: 0.3;
  }

  :hover:not(:disabled) {
    filter: brightness(1.2);
  }

  :active {
    opacity: 0.3;
  }
`
