import React, { Component } from 'react'
import MathJax from '../MathJax/MathJax'
import StepByStepSolution from '../StepByStepSolution/StepByStepSolution'
import CourseButton from '../CourseButton/CourseButton'
import config from '../../config'
import './css/show-solution.css'
import {
  isEmptyOrWhiteSpace,
  getHighlightedAnswer
} from '../MathType/utils'
import Context from '../Context/Context'
import ColoredScrollbars from '../ColoredScrollbars/ColoredScrollbars'
import { INCORRECT, CORRECT } from '../../Constants/result'
import { PROBLEM_BANK } from '../../Constants/sectionType'
import { ShowSolutionWrapper } from './styled/index'
import { formatQuestionExplanation } from '../../utilities/questions'
import { CompilationResult, CorrectCode, TitleWrapper } from '../PracticeExercise/styles'

class ShowSolution extends Component {
  constructor (props) {
    super(props)
    this.instArrow = this.instArrow.bind(this)
    this.state = {
      showSolutionList: true,
      transformStyle: {}
    }
  }

  async componentDidMount () {
    this.isStudentAccount()
  }

  instArrow () {
    const elList = document.getElementsByClassName('lesson-list')
    if (!elList || (elList && !elList[0])) return

    const listWidth = -elList[0].offsetWidth / 16
    this.setState({
      showSolutionList: !this.state.showSolutionList,
      transformStyle: this.state.showSolutionList
        ? {
          WebkitTransform: 'translateX(' + listWidth + 'rem)',
          transform: 'translateX(' + listWidth + 'rem)'
        }
        : {}
    })
  }

  async isStudentAccount () {
    const {
      isAdmin,
      isVIPGradedContent,
      isVIP
    } = this.context
    this.setState({ isStudentAccount: !(isAdmin || isVIP || isVIPGradedContent) })
  }

  getExplanation (explanation) {
    return (<>
      <div className={'answer-title text-left'} id='explanation'>
        Explanation
      </div>
      <div className='explanation' data-cy='explanation-text'>
        <MathJax math={formatQuestionExplanation(explanation)} />
      </div>
    </>)
  }

  render () {
    const {
      solutionData,
      sectionType,
      incorrectProps,
      correctProps,
      isPopupEditorQuestion,
      explanation,
      toggleQuestionSolution,
      isCodingQuestion,
      compilationScore,
      isExamOrQuizReviewMode
    } = this.props
    const { isStudentAccount } = this.state
    const { finalExamLockDate } = this.context
    const isFinalExamUnlocked = new Date().getTime() < (finalExamLockDate * 1000)

    if (!solutionData) return null

    const {
      view_lesson: viewLesson, studentAnswer, correctAnswer,
      studentAnswerClass
    } = solutionData
    const { text: feedbackText } = studentAnswerClass &&
    studentAnswerClass.includes('incorrect') ? incorrectProps || {} : correctProps || {}
    const { text: correctPropsText } = correctProps || {}
    const validStudentAnswer = !isEmptyOrWhiteSpace(studentAnswer)
    const text = validStudentAnswer ? feedbackText : correctPropsText
    const highlightedAnswer = getHighlightedAnswer(solutionData, isExamOrQuizReviewMode)
    const answerTextStyle = {
      ...(isPopupEditorQuestion && { 'text-align': 'left' })
    }
    const isAnswerCorrect = validStudentAnswer && !studentAnswerClass.includes('incorrect')

    const { flags: { showExplanation } } = config
    const isProblemSet = sectionType === PROBLEM_BANK
    const hideExplanation = config.course.hideQuestionExplanation &&
      isProblemSet
    const shouldShowExplanation = !hideExplanation || showExplanation

    const showStepByStepSolution = config.course.showStepByStepSolution &&
      ((isStudentAccount && isFinalExamUnlocked) ||
        !isStudentAccount || config.isPreviewCourse)

    return (
      <ShowSolutionWrapper
        className='lesson-list px-0 py-4 revealed-answer-wrapper'
        isExamOrQuizReviewMode={isExamOrQuizReviewMode}
      >
        <div className={'d-flex mb-4 align-items-center'}>
          <span className={`radio__badge radio__badge-xs ${studentAnswerClass}`} data-cy='studentAnswerCheckMark' />
          <span className={'answer-status'}>
            {isAnswerCorrect ? CORRECT : isExamOrQuizReviewMode ? INCORRECT : 'Try again'}
          </span>
          {toggleQuestionSolution &&
            <span className={'show-solution hide-solution'} onClick={toggleQuestionSolution}><span>HIDE SOLUTION</span></span>
          }
        </div>
        {!isCodingQuestion && validStudentAnswer && !isAnswerCorrect &&
        <>
          <div className={'answer-title text-left'}>YOUR ANSWER</div>
          <div className='mb-4 answer-content' id={'student-answer'}>
            <span className='mx-auto img-fluid revealed-answer-box'>
              <MathJax math={highlightedAnswer} />
            </span>
          </div>
        </>
        }
        {!isAnswerCorrect && (!isCodingQuestion || correctAnswer) && <>
          <div className={'answer-title text-left'}>CORRECT ANSWER</div>
          {isCodingQuestion
            ? <CorrectCode>{correctAnswer}</CorrectCode>
            : (
              <div className='mb-4 answer-content'>
                <span className='mx-auto img-fluid revealed-answer-box' style={answerTextStyle}>
                  <MathJax data-testid='correctAnswer' math={correctAnswer} />
                </span>
              </div>
            )}
        </>}
        {shouldShowExplanation && explanation && this.getExplanation(explanation)}
        {isCodingQuestion && isExamOrQuizReviewMode && text &&
          <TitleWrapper marginBottom='12'>FEEDBACK</TitleWrapper>}
        {studentAnswer ? null
          : <>
            {isExamOrQuizReviewMode && text &&
            <ColoredScrollbars autoHeight autoHeightMin={50} autoHeightMax={200}>
              <span className='mb-4 mx-auto img-fluid revealed-answer-box' style={answerTextStyle}>
                <MathJax math={this.props.filterMessage(text)} />
              </span>
            </ColoredScrollbars>}
          </>}
        {studentAnswer ? isExamOrQuizReviewMode && text &&
        <span id='revealedAnswer' className='mb-4 mx-auto img-fluid revealed-answer-box' style={answerTextStyle}>
          <MathJax math={this.props.filterMessage(text)} />
        </span>
          : null}
        {isCodingQuestion && (
          <CompilationResult className={`${isCodingQuestion ? 'mb-4' : ''}`}>
            <TitleWrapper>COMPILATION</TitleWrapper>
            <p className='result'>
              {compilationScore === 1
                ? 'Code compiled successfully.'
                : 'Code did not compile'
              }
            </p>
          </CompilationResult>
        )}
        {viewLesson && <div className='text-center'>
          <CourseButton
            className={{
              'btn-primary': true,
              'my-2': true,
              'mb-4': true
            }}
            value={viewLesson}
            onClick={() => window.open(viewLesson, '_blank')}
          >
            View Lesson
          </CourseButton>
        </div>}
        {
          showStepByStepSolution &&
          <StepByStepSolution />
        }
      </ShowSolutionWrapper>
    )
  }
}

ShowSolution.contextType = Context
ShowSolution.displayName = 'ShowSolution'
export default ShowSolution
