
import React, { Component } from 'react'
import MathJax from '../MathJax/MathJax'
import { EXAM, QUIZ } from '../../Constants/sectionType'
import debounce from 'lodash/debounce'
import {
  EYE_OPEN_ICON,
  NEW_COMPLETED_ICON,
  NEW_INCORRECT_QUESTION_ICON,
  NEW_CORRECT_UNATTEMPTED_QUESTION_ICON,
  NEW_INCORRECT_ATTEMPTED_QUESTION_ICON,
  EYE_CLOSED_ICON
} from '../../Constants/progressBarIconSource'
import { MULTIPLE_CHOICE } from '../../Constants/questionType'
// import Context from '../Context/Context'
import {
  OptionsWrapper,
  Label,
  Input,
  TextWrapper,
  CorrectAnswerText,
  IconWrapper,
  Icon
} from './style'

class Checkbox extends Component {
  constructor (props) {
    super(props)
    this.toggleCheckboxChange = debounce(this.toggleCheckboxChange.bind(this), 200,
      { leading: true, trailing: false })
    this.toggleCheckboxOnEyeOpen = this.toggleCheckboxOnEyeOpen.bind(this)
    this.state = {
      isEyeOpen: false,
      isDisabledBox: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { questionUUID } = this.props
    if (prevProps.questionUUID !== questionUUID) {
      this.setState({ isEyeOpen: false, isDisabledBox: false })
    }
  }

  toggleCheckboxChange () {
    const { handleCheckboxChange, value } = this.props
    handleCheckboxChange(value)
  }

  toggleCheckboxOnEyeOpen () {
    const { reviewMode, checked } = this.props
    const { isEyeOpen, isDisabledBox } = this.state
    checked && this.toggleCheckboxChange()
    !reviewMode &&
      this.setState({
        isEyeOpen: !isEyeOpen,
        isDisabledBox: !isDisabledBox
      })
  }

  getIcon () {
    const {
      value, isCorrect, reviewMode, checked, sysAnswer
    } = this.props
    const { isEyeOpen } = this.state
    const isCurrentCorrect = isCorrect === 'true'

    if (!reviewMode) {
      return isEyeOpen ? EYE_OPEN_ICON : EYE_CLOSED_ICON
    }
    if (checked) {
      return isCurrentCorrect
        ? NEW_COMPLETED_ICON
        : NEW_INCORRECT_ATTEMPTED_QUESTION_ICON
    }
    // setting isCurrentOptionCorrect to true if sysAnswer is equal to option value for case when answer is wrong and any option is not selected
    const isCurrentOptionCorrect = parseInt(sysAnswer[0]) - 1 === value
    return isCurrentOptionCorrect
      ? NEW_CORRECT_UNATTEMPTED_QUESTION_ICON
      : NEW_INCORRECT_QUESTION_ICON
  }

  render () {
    // const { label, value, fAnswer, sysAnswer , isCorrect , disabled} = this.props
    const {
      label,
      value,
      isCorrect,
      disabled,
      dataCypress,
      reviewMode,
      isLastIndex,
      checked,
      isAnsweringDisabled,
      sysAnswer,
      isAssessmentQuizExam,
      isALRedesign,
      isSidePanelOpen,
      type_of_question: typeOfQuestion,
      hideCorrectIndicator
    } = this.props

    const { isEyeOpen, isDisabledBox } = this.state
    const isCurrrentCorrect = isCorrect === 'true'
    const isPendingResponseAnswer = (!reviewMode ||
       (reviewMode && isAssessmentQuizExam)) &&
      [QUIZ, EXAM].includes(typeOfQuestion)
    const toggleIconSrc = this.getIcon()
    const isCurrentOptionCorrect = reviewMode && parseInt(sysAnswer[0]) - 1 === value
    var correctIncorrect = ''
    let checkDisabled = false

    if (isCurrrentCorrect) {
      correctIncorrect = isPendingResponseAnswer
        ? isAssessmentQuizExam
          ? 'pending-response-assessment'
          : 'pending-response' : 'ans-correct'
    } else if (isCorrect === 'false') {
      correctIncorrect = isPendingResponseAnswer
        ? isAssessmentQuizExam
          ? 'pending-response-assessment'
          : 'pending-response' : 'incorrect ans-incorrect'
    } else if (isCorrect === 'null') {
      correctIncorrect = isAssessmentQuizExam
        ? 'ans-correct-partial-assesment'
        : 'ans-correct-partial'
    } else {
      correctIncorrect = isCorrect
    }
    if (this.props.correctIncorrect === 'ans-correct-partial') {
      correctIncorrect = this.props.correctIncorrect
    }

    let inctOptions = ''
    if (correctIncorrect && correctIncorrect === 'incorrect ans-incorrect' && checked) {
      inctOptions = 'incorrect-options'
    }
    if (disabled) {
      checkDisabled = true
    }
    const answerCorrect = correctIncorrect === 'ans-correct'
    const isMultipleSelection = correctIncorrect === 'ans-correct-partial'
    return (
      <OptionsWrapper
        data-cy={dataCypress}
        isLastIndex={isLastIndex}
        isAssessmentQuizExam={isAssessmentQuizExam}
        isALRedesign={isALRedesign}
        isSidePanelOpen={isSidePanelOpen}
        hideCorrectIndicator={hideCorrectIndicator}
      >
        <Input
          type='checkbox'
          value={value}
          checked={this.props.checked}
          disabled={checkDisabled || (isEyeOpen && isDisabledBox)}
          isAssessmentQuizExam={isAssessmentQuizExam}
          isALRedesign={isALRedesign}
          onChange={() => {
            if (!checkDisabled) this.toggleCheckboxChange()
          }}
          hideCorrectIndicator={hideCorrectIndicator}
        />
        <Label
          className={
            !isALRedesign &&
            `${inctOptions}
          ${checked ? correctIncorrect : ''}`
          }
          isMultipleSelection={isMultipleSelection}
          isIncorrectOption={!!inctOptions}
          isAssessmentQuizExam={isAssessmentQuizExam}
          isALRedesign={isALRedesign}
          isPendingResponseAnswer={isPendingResponseAnswer}
          isEyeOpen={isEyeOpen}
          typeOfQuestion={typeOfQuestion}
          isAnsweringDisabled={isAnsweringDisabled}
          reviewMode={reviewMode}
          checked={checked}
          isCorrect={isCurrrentCorrect}
          isCurrentOptionCorrect={isCurrentOptionCorrect}
          type={MULTIPLE_CHOICE}
          hideCorrectIndicator={hideCorrectIndicator}
        >
          {!hideCorrectIndicator && checked &&
            (isALRedesign || answerCorrect) && !isPendingResponseAnswer
            ? <IconWrapper
              isSidePanelOpen={isSidePanelOpen}
              isALRedesign={isALRedesign}>
              {isALRedesign && answerCorrect
                ? <Icon src={NEW_COMPLETED_ICON} alt='Correct' />
                : !answerCorrect && isALRedesign && !isMultipleSelection &&
                <Icon src={NEW_INCORRECT_ATTEMPTED_QUESTION_ICON}
                  alt='InCorrect' />
              }
              {!isALRedesign &&
                <Icon src='images/icons/correct-answer.png'
                  alt='Correct' />}
            </IconWrapper>
            : !hideCorrectIndicator && (!disabled || reviewMode) &&
            (isAssessmentQuizExam || isALRedesign) &&
            <IconWrapper
              reviewMode={reviewMode}
              isAssessmentQuizExam={isAssessmentQuizExam}
              isSidePanelOpen={isSidePanelOpen}
              isALRedesign={isALRedesign}>
              <Icon
                src={toggleIconSrc}
                alt='Incorrect'
                onClick={this.toggleCheckboxOnEyeOpen}
              />
            </IconWrapper>}
          <TextWrapper isAssessmentQuizExam={isAssessmentQuizExam}
            isEyeOpen={isEyeOpen}
            isALRedesign={isALRedesign}>
            { checked && isAssessmentQuizExam && reviewMode &&
             isCurrrentCorrect &&
             <CorrectAnswerText>
                Correct Answer
             </CorrectAnswerText>
            }
            { checked && isALRedesign &&
            <CorrectAnswerText isALRedesign={isALRedesign}>
              {isCurrrentCorrect
                ? 'Correct Answer'
                : !isCurrrentCorrect && !isMultipleSelection &&
                'Incorrect Answer'}
            </CorrectAnswerText>
            }
            {<MathJax math={label} isQuestionOption />}
          </TextWrapper>
        </Label>
      </OptionsWrapper>
    )
  }
}

export default Checkbox
