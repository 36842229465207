import React from 'react'
import CourseButton from '../CourseButton/CourseButton'
import '../../App.css'
import Context from '../Context/Context'
import SidePanel from '../../Components/QuestionSetProgress/SidePanel'
import Button from '../FormComponent/Button'
import { fillQuestions } from '../InstructorQuestionSet/InstructorQuestionSet'
import PracticeExerciseIntro from '../PracticeExercise/PracticeExerciseIntro'
import {
  QuestionIntroUI,
  MainWrapper,
  PageTitle,
  ALSetIndicator,
  ButtonDiv
} from './styles/index'

// import { Container, Row, Col } from 'reactstrap'
import './css/interstitial.css'
import { emitter } from '../Emitter/Emitter'
import config from '../../config'
import {
  ON_INSTRUCTOR_SWITCH,
  ON_SKIP_SECTION,
  ON_NAVIGATE_TO,
  ON_IS_LAST_SECTION,
  ON_REVIEW_MODE
} from '../../Constants/emitterKeys'
import { INTRO } from '../../Constants/introText'
import {
  PRACTICE_EXERCISES, QUIZ, EXAM, GUESSWORK, ACTIVE_LEARNING
} from '../../Constants/sectionType'
import { getProblemSetQuestions } from '../../utilities/problemBankUtils'
import { getButtonClass } from '../../utilities/studentProgressUtils'
import { isOrientationChapter, trackStudentEvent } from '../../utilities/chapterUtils'
import SidePanelWithPin from '../QuestionSetProgress/SidePanelWithPin'

class QuestionSetInterstitial extends React.Component {
  constructor (props) {
    super(props)
    this.filteredquestionSetFun = this.filteredquestionSetFun.bind(this)
    this.selectInstructor = this.selectInstructor.bind(this)
    this.onButtonClick = this.onButtonClick.bind(this)
    this.createButton = this.createButton.bind(this)
    this.createButtons = this.createButtons.bind(this)
    this.getQuizNumber = this.getQuizNumber.bind(this)
    this.onDropdownSelected = this.onDropdownSelected.bind(this)
    this.createSelectInstructor = this.createRadioInstructor.bind(this)
    this.state = {
      QuestionSet: [],
      isNextDisabled: true
    }
    // this.filteredquestionSetFun = this.filteredquestionSetFun
    this.instructors = []
  };

  filteredquestionSetFun () {
    if (this.props.screenData && this.props.screenData[0]) {
      const filteredQuestionSet = this.props.screenData.filter((el) => {
        return el.instructor.instructor_uuid === this.context.course['currentInstructor']
      })
      return filteredQuestionSet
    }
  }

  async componentDidMount () {
    const { isCalculusCourse } = config
    const { QuestionSetdata, activeSectionUUID, activeQuizUUID } = this.props
    const { problemBank,
      currentProblemSetUUID,
      courseSections,
      worktype } = QuestionSetdata
    const {
      course,
      currentChapter: { type: chapterType },
      studentData,
      reviewMode,
      updateContext,
      currentActiveLearningTheme,
      courseData: { instructor: courseInstructors }
    } = this.context
    const activeTheme = currentActiveLearningTheme !== ''
      ? currentActiveLearningTheme
      : localStorage.getItem('last_viewed_theme')
    const isActiveLearning = worktype === ACTIVE_LEARNING
    const isCalculusAL = isActiveLearning && isCalculusCourse()
    const isChapterOrientation = isOrientationChapter({ type: chapterType })
    const sections = [PRACTICE_EXERCISES, QUIZ]
    const { 'quiz-section': quizSection } = studentData
    if (worktype === 'Quiz') {
      updateContext({ isAssessmentQuizExam: true, sidePanelOpenState: 1 })
    }
    if (isActiveLearning) {
      updateContext({ isALRedesign: true })
    }

    if (isCalculusAL) {
      sections.push(ACTIVE_LEARNING)

      if (!isChapterOrientation) {
        const currentInstructor = courseInstructors?.find(data =>
          data.theme_name === activeTheme) || {}
        emitter.emit(ON_INSTRUCTOR_SWITCH, currentInstructor.instructor_uuid)
      }
    }

    if (worktype === 'Quiz' &&
       activeQuizUUID in quizSection &&
       !reviewMode) {
      emitter.emit(ON_REVIEW_MODE, true)
    }
    let problemSetQuestions
    if (problemBank) {
      problemSetQuestions = await getProblemSetQuestions(this.context, { problemBank,
        currentProblemSetUUID }, { activeSectionUUID, courseSections })
    }
    this.setState({
      QuestionSet: QuestionSetdata, problemSetQuestions
    })
    emitter.emit(ON_IS_LAST_SECTION)
    var temp = {}
    var questions = QuestionSetdata.questions
    if (questions) {
      for (let i = 0; i < questions.length; i++) {
        if (questions[i].instructor.instructor_uuid) {
          temp[questions[i].instructor.instructor_uuid] = questions[i].instructor
        }
      }
      var i = 0
      for (var o in temp) {
        this.instructors[i] = []
        this.instructors[i]['instructor'] = temp[o]
        i++
      }
      if (course['currentInstructor']) { this.onDropdownSelected(course['currentInstructor']) }
    }

    // Hack to get stats, astro and psychology course AL begin button to work for demo. Should be
    // fixed properly by removing instructors from those AL questions in Dato.
    const isExceptionCourse = [
      config.courseIds.psychology,
      config.courseIds['psychology.plus'],
      config.courseIds.statistics,
      config.courseIds['statistics.plus'],
      config.courseIds.astronomy,
      config.courseIds['astronomy.plus']
    ].includes(config.courseId)

    this.setState({
      isNextDisabled: !isChapterOrientation && !!(this.instructors && this.instructors.length > 0) &&
      !isExceptionCourse
    })

    if (this.context.course['currentInstructor'] && this.context.course['currentInstructor'] !== '') {
      this.setState({
        isNextDisabled: false
      })
    }

    if (sections.includes(this.props.QuestionSetdata.worktype)) {
      this.setState({ isNextDisabled: false })
    }
  }

  selectInstructor (e) {
    // emitter.emit('instructor-switch', e)
    if (this.state.isNextDisabled !== false) {
      this.setState({
        isNextDisabled: false
      })
    }
  }

  onButtonClick (uuid) {
    const {
      activeSectionUUID,
      activeChildrenIndex,
      completeScreenData
    } = this.props
    const { QuestionSet } = this.state
    const { updateContext, cohortData: { name: cohort, duration } } =
     this.context
    emitter.emit(ON_NAVIGATE_TO, '/' +
      activeSectionUUID + '/' + uuid)
    updateContext({ navigateToActiveIndex: true })
    const isQuiz = QuestionSet.worktype === QUIZ
    isQuiz && trackStudentEvent({
      questionType: QuestionSet.worktype,
      duration,
      cohort,
      activeChildrenIndex,
      completeScreenData
    })
    window.outlierLog('Click', 'Begin')
  }

  createButton (label, index) {
    const {
      QuestionSetdata
    } = this.props
    const { worktype: sectionType } = QuestionSetdata
    const {
      reviewMode,
      currentChapter: { type },
      studentData
    } = this.context
    const isActiveLearning = sectionType === ACTIVE_LEARNING
    const isAssessmentQuizExam = sectionType === 'Quiz'

    return (<Button
      key={label.uuid}
      value={index}
      QuestionSetdata={QuestionSetdata}
      isActiveLearning={isActiveLearning}
      label={label}
      sectionType={'exam'}
      dataCypress='progressBar'
      btnClass={getButtonClass({
        label, sectionType, reviewMode, type, studentData
      })}
      doNavigate={this.onButtonClick}
      isAssessmentQuizExam={isAssessmentQuizExam}
    />)
  }

  createButtons (items) {
    return items.map(this.createButton)
  }

  onDropdownSelected (value) {
    emitter.emit(ON_INSTRUCTOR_SWITCH, value)
    for (let i = 0; i < this.instructors.length; i++) {
      if (value === this.instructors[i].instructor.instructor_uuid) {
        window.outlierLog('Select Theme', this.instructors[i].instructor.theme_name)
      }
    }
    if (value && value !== 'ce') {
      this.setState({
        isNextDisabled: false
      })
    } else {
      this.setState({
        isNextDisabled: true
      })
    }
  }

  createRadioInstructor (onDropdownSelected) {
    const items = []
    // var selected = (this.context.course['currentInstructor'] !== '') ? this.context.course['currentInstructor'] : ''
    for (let i = 0; i < this.instructors.length; i++) {
      /* if(selected === this.instructors[i].instructor.instructor_uuid){
        selected = 'selected = selected';
      } */
      items.push(
        <label key={i + 1} className='radio-item-container'>
          <input type='radio'
            value={this.instructors[i].instructor.instructor_uuid}
            name='chooseTheme'
            data-cy={this.instructors[i].instructor.theme_name}
            onChange={(e) => onDropdownSelected(e.target.value)}
            checked={this.context.course['currentInstructor'] === this.instructors[i].instructor.instructor_uuid} />
          <span className='checkmark' />
          {this.instructors[i].instructor.theme_name}
        </label>
      )
    }
    return items
  }

  getQuizNumber () {
    const activeChildrenIndex = this.props.activeChildrenIndex
    const quiz = this.props.completeScreenData.children[activeChildrenIndex]
    return quiz.section_quiz_number
  }

  render () {
    const { QuestionSet, isNextDisabled, problemSetQuestions } = this.state
    const {
      activeSectionUUID,
      QuestionSetdata: { problemBank },
      activeChildrenIndex,
      completeScreenData
    } = this.props
    const breadCrumbLength = completeScreenData?.breadcrumbArr?.length ?? 0
    const baseTitle = completeScreenData?.breadcrumbArr?.[breadCrumbLength - 1][0]
    let nextChildUUID = ''
    let filteredQuestionSet = []

    if (!this.props.intertitial_type) {
      if (this.context.course['currentInstructor'] !== 'ce' && this.context.course['currentInstructor']) {
        filteredQuestionSet = this.filteredquestionSetFun()
        if (filteredQuestionSet && filteredQuestionSet.length > 0) {
          nextChildUUID = filteredQuestionSet[0].Question_uuid
        } else {
          nextChildUUID = this.props.nextChildUUID
        }
      } else {
        nextChildUUID = this.props.nextChildUUID
      }
    }
    if (problemBank && problemSetQuestions) {
      const {
        Question_uuid: questionUUID,
        questionSetUUID } = problemSetQuestions[0] || {}
      nextChildUUID = `${questionSetUUID}_${questionUUID}`
    }

    const {
      currentChapter: { type: chapterType },
      cohortData: { name: cohort, duration },
      updateContext,
      isStudioCohort,
      currentActiveLearningTheme,
      sidePanelOpenState,
      isSidePanelOpen
    } = this.context
    const isChapterOrientation = isOrientationChapter({ type: chapterType })
    const {
      QuestionSetdata,
      QuestionSetdata: { questions, worktype }
    } = this.props
    const activeTheme = currentActiveLearningTheme !== ''
      ? currentActiveLearningTheme
      : localStorage.getItem('last_viewed_theme')
    const { isCalculusCourse } = config
    const isQuiz = worktype === QUIZ
    const isExam = worktype === EXAM
    const isActiveLearning = worktype === ACTIVE_LEARNING
    const isAssessmentQuizExam = (isQuiz || isExam)
    const isQuizExamOrAL = isAssessmentQuizExam || isActiveLearning
    const isALCalculusRedesign = isActiveLearning && isCalculusCourse()
    let progressBubblesButtons
    if (isQuizExamOrAL) {
      const testResults = fillQuestions(
        (isALCalculusRedesign && !isChapterOrientation)
          ? filteredQuestionSet : questions
      )
      const questionsInfo = Array.from(Object.keys(testResults), k => testResults[k])
      progressBubblesButtons = this.createButtons(questionsInfo)
    }

    if (QuestionSet) {
      const { title } = QuestionSet
      const blockTitle = isQuiz ? (title || worktype) : worktype
      const introText = (isQuiz && isStudioCohort) ? INTRO[QUIZ] : QuestionSet.intro_text
      const pageTitleAL = `${baseTitle}${activeTheme ? ` (${activeTheme})` : ''}`

      if (worktype === PRACTICE_EXERCISES) {
        return <PracticeExerciseIntro
          QuestionSetdata={QuestionSetdata}
          activeSectionUUID={activeSectionUUID}
          breadCrumb={completeScreenData?.breadcrumb}
          nextChildUUID={nextChildUUID}
        />
      }

      return (
        <section className='section guess-work quiz-page'>
          {!isQuizExamOrAL ? <div className='guess__work-main'>
            <div className='guess__work-main-mid'>
              <div className='guess__work-block mb-1'>
                {QuestionSet.intro_image &&
                  <img
                    className='img-fluid'
                    src={QuestionSet.intro_image}
                    width='441'
                    height='213'
                    alt='Interstitial Intro' />}
                <div className='component__backdrop backdrop__bottom-rounded'>
                  <h2 className='guess__work-block-title'>{blockTitle}</h2>
                  <p className='pb-3' dangerouslySetInnerHTML={{ __html: (introText) }} />
                  {
                    (QuestionSet.worktype === GUESSWORK)
                      ? this.selectInstructor('')
                      : (
                        this.instructors && this.instructors.length > 0 &&
                        config.course.hasActiveLearningTheme &&
                          !isChapterOrientation &&
                          this.props.QuestionSetdata.worktype === ACTIVE_LEARNING
                      )
                        ? <div className='text-center'>
                          <div className='separator' />
                          <div className='interstitial-wrapper'>
                            <p>Select Theme</p>
                            <div data-cy='instructorSelect' className='selectContainer'>
                              {this.createRadioInstructor(this.onDropdownSelected)}
                            </div>
                          </div>
                        </div>
                        : null
                  }
                  <div className='text-center'>
                    {
                      (this.props.useFor === 'pretest')
                        ? <CourseButton
                          className={{
                            'btn-primary': true
                          }}
                          data-testid='beginButton'
                          onClick={() => {
                            emitter.emit(ON_NAVIGATE_TO, '/' + nextChildUUID)
                            window.outlierLog('Click', 'Begin')
                          }}
                          disabled={isNextDisabled}
                        >
                              Begin
                        </CourseButton>
                        : (
                          this.props.intertitial_type &&
                          this.props.intertitial_type === 'end_content_intertitial'
                        )
                          ? <CourseButton
                            className={{
                              'btn-primary': true
                            }}
                            data-testid='nextButton'
                            onClick={() => {
                              emitter.emit(ON_SKIP_SECTION, '/' + activeSectionUUID)
                              window.outlierLog('Click Next', 'End content interstitial')
                            }}
                            disabled={isNextDisabled}
                          >
                            Next
                          </CourseButton>
                          : <CourseButton
                            className={{
                              'btn-primary': true
                            }}
                            onClick={() => {
                              const { updateContext } = this.context
                              emitter.emit(ON_NAVIGATE_TO, '/' +
                                activeSectionUUID + '/' + nextChildUUID)
                              updateContext({ navigateToActiveIndex: true })
                              const isQuiz = QuestionSet.worktype === QUIZ
                              isQuiz && trackStudentEvent({
                                questionType: QuestionSet.worktype,
                                duration,
                                cohort,
                                activeChildrenIndex,
                                completeScreenData
                              })
                              window.outlierLog('Click', 'Begin')
                            }}
                            disabled={isNextDisabled}
                            dataCypress='beginButton'
                          >
                            Begin
                          </CourseButton>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
            : <MainWrapper
              isALRedesign={isActiveLearning}
              isSidePanelOpen={isSidePanelOpen}>
              <QuestionIntroUI
                isAssessment={isAssessmentQuizExam}
                isALRedesign={isActiveLearning}
                isSidePanelOpen={isSidePanelOpen}>
                {isActiveLearning &&
                  <ALSetIndicator>Active Learning</ALSetIndicator>}
                <PageTitle isActiveLearning={isActiveLearning}>
                  {isActiveLearning ? pageTitleAL : `Quiz ${this.getQuizNumber()}`}
                </PageTitle>
                <div>{(QuestionSet.intro_text)}</div>
                <ButtonDiv isActiveLearning={isActiveLearning}><CourseButton
                  className={{
                    'btn-primary': true
                  }}
                  onClick={() => { this.onButtonClick(nextChildUUID) }}
                  disabled={isNextDisabled}
                >
                  <img src='/images/icons/icon-right-arrow.svg' alt='right-arrow-icon' />
                </CourseButton></ButtonDiv>
              </QuestionIntroUI></MainWrapper>
          }
          {isQuizExamOrAL && !config.hasPinnedActiveLearningFlag() && <div>
            <SidePanel
              progressBubbleButtons={progressBubblesButtons}
              sidePanelOpenState={sidePanelOpenState}
              QuizIntroPage
              isALRedesign={isActiveLearning}
              isLastQuestion={this.context.isLastQuestionAnswered}
              updateContext={updateContext} />
          </div>}
          {isQuizExamOrAL && config.hasPinnedActiveLearningFlag() && <div>
            <SidePanelWithPin
              progressBubbleButtons={progressBubblesButtons}
              sidePanelOpenState={sidePanelOpenState}
              QuizIntroPage
              isALRedesign={isActiveLearning}
              isLastQuestion={this.context.isLastQuestionAnswered}
              updateContext={updateContext} />
          </div>}
        </section>
      )
    } else {
      return (<div className='text-center'>Loading...</div>)
    }
  }
}

QuestionSetInterstitial.contextType = Context
export default QuestionSetInterstitial
