import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  ${({ isALRedesign }) => !isALRedesign && `
    body {
      padding: 0;
    }
  
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #1D1D1F;
    }

    ::-webkit-scrollbar-thumb {
      background: #5B6067;
      border-radius: 5px;
    }
  `} 
`

export const OffsetContainer = styled.div`
  ${props => !props.isStandAloneCompiler
    ? `
      width: 615px;
      margin-left: -58px;
    `
    : `height: 700px;`}
`

export const JDoodleContainer = styled.div`
  // this prevents content from showing before the iframe is loaded
  color: transparent!important;

  height: 100vh;
  overflow: hidden;
  & > iframe {
    height: 100vh;
  }
`
