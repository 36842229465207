import React, { useState, useContext, useRef, useEffect, useCallback } from 'react'
import api from '../../api'
import Context from '../Context/Context'
import { emitter } from '../Emitter/Emitter'
import FileUploader from '../FileUploader/FileUploader'
import TextEditor from '../TextEditor/TextEditor'
import Modal from './Modal'
import CustomCheckbox from '../CustomCheckbox'
import ConfirmationModal from '../Modals/ConfirmationModal/Modal'
import {
  secondsSinceEpoch
} from '../../utilities/dateTimeUtils'
import {
  copyStudentProgress,
  getStudentProgressDiff
} from '../../utilities/courseUtils'
import {
  ON_NAVIGATE_TO,
  ON_MINIMUM_SECTION_PROGRESS,
  ON_SECTION_PROGRESS,
  ON_SECTION_DATA_PROGRESS,
  ON_STUDENT_ANSWER,
  ON_SUBMIT_ANSWER,
  ON_TRACK_STUDENT_EVENT
} from '../../Constants/emitterKeys'
import {
  ASSIGNMENT_PROGRESS,
  ASSIGNMENT_FILE_METADATA
} from '../../Constants/studentContext'
import {
  SubmissionHeading,
  SubmissionParagraph,
  SubmitButton,
  TextAreaWrap,
  CheckboxWrapper
} from './styles'
import config from '../../config'
import {
  getCodingAssignmentResults,
  submitCodingAssignment
} from '../../utilities/codegradeUtils'
import { ASSIGNMENT_COMPLETED } from '../../Constants/eventTypes'
import { MULTIPLE } from '../../Constants/frequency'
import { ASSIGNMENT_PAGE_LAYOUTS } from '../../Constants'
import { isEmpty } from 'lodash'
import ExitModal from './ExitModal'
import { isClickable } from '../../utilities'

const activeInputStates = {
  TEXTENTRY: 'TEXTENTRY',
  FILEUPLOAD: 'FILEUPLOAD',
  NONE: 'NONE'
}

const { RESUBMIT } = ASSIGNMENT_PAGE_LAYOUTS

const AssignmentSubmissionPage = (props) => {
  const {
    assignmentUUID,
    cohortID,
    lockTimeInSeconds,
    hideTextEntryField,
    hideFileUploadField,
    title,
    isSubmitDisabled,
    setLoading,
    file,
    setFile,
    text,
    setText,
    content,
    setContent,
    codegradeAssignmentId,
    isCodingAssignment,
    hasStarterFiles,
    layout,
    allowSpreadsheetUpload,
    multiPartUpload,
    affirmationStatements,
    multiPartUploadfiles = [{}],
    assignmentType
  } = props
  const context = useContext(Context)
  const {
    isAdmin,
    updateContext,
    cohortData,
    courseID,
    studentData,
    submitAssignments,
    cancelResubmission,
    courseData
  } = context
  const {
    name: cohortName,
    officialCourseName,
    duration
  } = cohortData

  const { [ASSIGNMENT_PROGRESS]: assignmentProgress } = studentData
  const { [assignmentUUID]: { status: assignmentStatus } = {} } = assignmentProgress || {}
  const isDraft = assignmentStatus === 'draft'
  const isResubmitPage = layout === RESUBMIT
  const [activeInput, setActiveInput] = useState(activeInputStates.NONE)
  const [modal, setModal] = useState({
    show: false,
    content: ''
  })
  const [tempFile, setTempFile] = useState({})
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showExitModal, setShowExitModal] = useState(false)
  const [eventStore, setEventStore] = useState(null)
  const [checkedAffirmations, setCheckedAffirmations] = useState({})
  const [isMultiPartUploading, setIsMultiPartUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState({})
  const submissionResponseRef = useRef(null)
  const getAssignmentEventData = () => {
    const AssignmentNumber = courseData.chapters?.findIndex(
      (chapter) => chapter.chapter_uuid === assignmentUUID) + 1

    return {
      event: ASSIGNMENT_COMPLETED,
      properties: {
        course: officialCourseName,
        cohort: cohortName,
        cohort_length: duration,
        assignment_name: title,
        assignment_type: assignmentType,
        assignment_number: AssignmentNumber,
        time_stamp: Date.now()
      },
      frequency: MULTIPLE
    }
  }

  const isInputDisabled = (input) => {
    return activeInput === activeInputStates.NONE ? false : !(activeInput === input)
  }

  const getErrorContent = (header, description) => (
    <>
      <h1>{header || 'Error submitting content'}</h1>
      <p>
        {description || 'Please try to submit the content again.'}
      </p>
      <SubmitButton className='btn btn-primary' onClick={() => {
        setModal({ show: false })
      }} >OK</SubmitButton>
    </>
  )

  const saveProgress = async (status, grade) => {
    emitter.emit(ON_SECTION_DATA_PROGRESS,
      {
        key: ASSIGNMENT_PROGRESS,
        sectionUUID: assignmentUUID,
        value: {
          status,
          ...(grade && { grade }),
          cohortId: cohortID,
          deadlineTime: lockTimeInSeconds,
          submissionTime: secondsSinceEpoch() }
      }
    )

    if (status !== 'submitted') return
    emitter.emit(ON_SECTION_PROGRESS, assignmentUUID)
    emitter.emit(ON_MINIMUM_SECTION_PROGRESS, assignmentUUID)
  }

  const copyStudentProgressContext = (context) => {
    return copyStudentProgress(context.studentData)
  }

  const logStudentProgressDiffContext = (beforeStudentData, context) => {
    return getStudentProgressDiff(
      beforeStudentData,
      context.studentData
    )
  }

  const saveFilename = filename => {
    const newContext = { ...context }
    const beforeStudentData = copyStudentProgressContext(newContext)
    const studentSectionData = studentData[ASSIGNMENT_FILE_METADATA]
    studentSectionData[assignmentUUID] = {
      originalFileName: filename,
      cohortId: cohortID }
    studentData[ASSIGNMENT_FILE_METADATA] = studentSectionData
    logStudentProgressDiffContext(beforeStudentData, newContext)
    updateContext({ studentData })
  }

  const saveFile = async (fileObj) => {
    var formData = new FormData()
    formData.append('assignment', fileObj)
    const result = await api.saveWritingAssignmentFile({
      assignmentUUID, cohortID, formData
    })
    return result
  }

  const handleCodingAssignment = async () => {
    setLoading(true)

    const formData = new FormData()
    formData.append('assignment', isResubmitPage ? tempFile?.[0] : file)
    await api.uploadCodingAssignment({
      courseId: courseID,
      cohortId: cohortID,
      assignmentUUID,
      formData
    })

    const studentAnswer = await getCodingAssignmentResults({
      assignmentUUID,
      codegradeAssignmentId,
      submissionResponse: submissionResponseRef.current
    })
    emitter.emit(ON_STUDENT_ANSWER, studentAnswer)
    emitter.emit(ON_SUBMIT_ANSWER, studentAnswer)
    const { totalScore, totalWeight } = studentAnswer?.meta || {}
    const grade = (totalScore / totalWeight) * 100
    await saveProgress('submitted', grade)
    emitter.emit(ON_NAVIGATE_TO, `/${assignmentUUID}/${assignmentUUID}_end_section`)
    setLoading(false)

    const eventData = getAssignmentEventData()
    emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
  }

  const handleCodingAssignmentFile = async fileObj => {
    activeInput !== activeInputStates.FILEUPLOAD &&
    setActiveInput(activeInputStates.FILEUPLOAD)

    const submissionResponse = await submitCodingAssignment(
      { assignmentId: codegradeAssignmentId, file: fileObj }
    )
    submissionResponseRef.current = submissionResponse
    setFile({ 0: fileObj })
    return true
  }

  const handleUploadedFile = async fileObj => {
    activeInput !== activeInputStates.FILEUPLOAD &&
      setActiveInput(activeInputStates.FILEUPLOAD)

    const fileUploaded = await saveFile(fileObj)
    if (!fileUploaded) {
      setModal({
        show: true,
        content: getErrorContent(
          'Error when uploading file',
          'Please try to upload the file again.'
        )
      })
      return false
    }

    setFile({ 0: fileObj })
    saveFilename(fileObj.name)
    if (!isResubmitPage) {
      await saveProgress('draft')
    }
    return true
  }

  const getSubmissionText = () => {
    if (isResubmitPage) {
      return `Submit your work through File Upload or Text Entry. Resubmission is available until the assignment’s due date.
      Note: Please review the assignment’s submission details as Text Entry may be disabled for the assignment.`
    }
    if (multiPartUpload) {
      return `Submit your work below in the correct space for each file.
      Resubmission is available until the assignment’s due date.
      Please confirm the following before submitting your assignment:`
    }
    if (
      showJDoodleCompiler ||
        isCodingAssignment ||
        hasStarterFiles ||
        hideTextEntryField
    ) {
      return 'Submit your work through File Upload below. Once you submit your work, the submission is final.'
    }
    if (hideFileUploadField) {
      return 'Submit your work through the Text Entry below. Once you submit your work, the submission is final.'
    }
    return `Submit your work through File Upload or Text Entry.
    Using one method will disable the other field.
    If you wish to switch, simply clear the text or remove the file below.
    Once you submit your work, the submission is final.`
  }

  const saveText = async (textContent) => {
    const textSaved = await api.saveWritingAssignmentText(
      assignmentUUID, cohortID, { assignmentHTML: textContent })
    return textSaved
  }

  const handleText = async textContent => {
    setText(textContent)
    setActiveInput(activeInputStates.TEXTENTRY)

    const textSaved = await saveText(textContent)
    if (!textSaved) {
      setModal({
        show: true,
        content: getErrorContent(
          'Error when saving content',
          'Please try to input your content again.'
        )
      })
      return false
    }

    await saveProgress('draft')
    return true
  }

  const shouldButtonDisable = () => {
    if (multiPartUpload) {
      const areAllFilesSelected =
        Object.values(tempFile).filter((value) => value)?.length ===
        multiPartUploadfiles?.length
      const areAllAffirmationsChecked =
        Object.values(checkedAffirmations).every(value => value)
      return !areAllFilesSelected || !areAllAffirmationsChecked
    }

    const areAllTempFilesNotSelected =
      !Object.values(tempFile).every((value) => value) || isEmpty(tempFile)

    if (
      activeInput === activeInputStates.FILEUPLOAD &&
      areAllTempFilesNotSelected &&
      !isDraft
    ) return true

    if (
      activeInput === activeInputStates.TEXTENTRY &&
      text === content?.[0]?.text &&
      !isDraft
    ) return true

    if (
      activeInput === activeInputStates.TEXTENTRY &&
      areAllTempFilesNotSelected &&
      !text
    ) return true

    if (
      isDraft &&
      activeInput === activeInputStates.NONE
    ) return true

    return false
  }

  const onSubmit = async () => {
    if (isResubmitPage && !multiPartUpload) {
      updateContext({
        isAssignmentResubmitted: true,
        showFinish: false
      })
      if (isCodingAssignment) return handleCodingAssignment()
      if (Object.values(tempFile)?.every((value) => value) &&
        !isEmpty(tempFile)
      ) {
        setLoading(true)
        const response = await handleUploadedFile(tempFile?.[0])
        if (!response) return setLoading(false)
      }
      return submitContent()
    }

    setIsMultiPartUploading(true)

    context.updateContext({
      isMultiPartUploading: true
    })

    for (const entries of Object.entries(tempFile)) {
      const [index, file] = entries
      const formData = new FormData()
      formData.append('assignment', file)

      const onUploadProgress = (e) => {
        const progress = Math.round((e.loaded * 100) / e.total)
        setUploadProgress(prev => ({
          ...prev,
          [index]: progress + '%'
        }))
      }

      const responseMessage = await api.saveWritingAssignmentFile({
        assignmentUUID,
        cohortID,
        formData,
        fileName: `file${index}`,
        multiPartAssignment: true,
        configuration: { onUploadProgress }
      })

      if (responseMessage !== 'file uploaded') {
        setModal({
          show: true,
          content: getErrorContent(
            'Error when uploading file',
            'Please try to upload the file again.'
          )
        })
        context.updateContext({
          isMultiPartUploading: false,
          submitAssignments: false
        })
        return setIsMultiPartUploading(false)
      }
    }
    setIsMultiPartUploading(false)
    await saveProgress('submitted')

    updateContext({
      isMultiPartUploading: false,
      showFinish: false,
      isMultiPartUploadingModal: false,
      isResubmitModal: false,
      isAssignmentResubmitted: isResubmitPage
    })
    emitter.emit(ON_NAVIGATE_TO, `/${assignmentUUID}/${assignmentUUID}_end_section`)
  }

  const onCancel = () => {
    const isTempFileEmpty = Object.values(tempFile).every(file => !file)
    return (multiPartUpload && isTempFileEmpty) ||
      (!multiPartUpload && shouldButtonDisable())
      ? emitter.emit(ON_NAVIGATE_TO, '/')
      : setShowConfirmationModal(true)
  }

  const getSupportedFileTypes = (file) => {
    const fileType = file?.type?.[0]
    if (fileType) return fileType
    return showJDoodleCompiler
      ? ` .java, .zip`
      : `.doc, .docx, .rtf, .txt, .pdf or .odt` + (allowSpreadsheetUpload ? `, .xls, .xlsx` : '')
  }

  const submitContent = async () => {
    setLoading(true)
    /* When user clicks Submit immediately after making an edit, debounce in
    TextEditor will be cancelled, and handleText will not be called. This condition
    is to ensure the modified text is saved before submitting assignment */
    if (activeInput === activeInputStates.TEXTENTRY) {
      const response = await saveText(text)
      if (!response) {
        setModal({
          show: true,
          content: getErrorContent()
        })
        setContent({ text })
        return setLoading(false)
      }
    }
    await saveProgress('submitted')
    emitter.emit(ON_NAVIGATE_TO, `/${assignmentUUID}/${assignmentUUID}_end_section`)
    setLoading(false)

    const eventData = getAssignmentEventData()
    emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
  }

  useEffect(() => {
    if (updateContext) {
      updateContext({
        submitAssignment:
          isCodingAssignment ? handleCodingAssignment : submitContent
      })
    }
    // eslint-disable-next-line
  }, [isSubmitDisabled, text])

  useEffect(() => {
    if ((!multiPartUpload && !isResubmitPage) || !updateContext) return
    updateContext({
      showFinish: true,
      isModalSubmitDisabled: true,
      isMultiPartUploadingModal: multiPartUpload,
      isAssessmentQuizExam: multiPartUpload,
      isResubmitModal: isResubmitPage
    })
    // eslint-disable-next-line
  }, [layout])

  useEffect(() => {
    if (!submitAssignments) return
    onSubmit()
    // eslint-disable-next-line
  }, [submitAssignments])

  useEffect(() => {
    if (!updateContext) return
    updateContext({
      isModalSubmitDisabled: shouldButtonDisable()
    })
    // eslint-disable-next-line
  }, [
    tempFile,
    checkedAffirmations,
    text,
    content,
    isDraft,
    multiPartUpload,
    activeInput
  ])

  useEffect(() => {
    if (!cancelResubmission) return setShowConfirmationModal(false)
    onCancel()
    // eslint-disable-next-line
  }, [cancelResubmission])

  useEffect(() => {
    return () => {
      if (!updateContext) return
      updateContext({
        isResubmitModal: false,
        isMultiPartUploadingModal: false,
        isModalSubmitDisabled: false,
        cancelResubmission: false,
        submitAssignments: false
      })
    }
    // eslint-disable-next-line
  }, [])

  const handleLeave = () => {
    if (eventStore) {
      const { event, target } = eventStore
      const newEvent = new event.constructor(event.type, event)
      target.dispatchEvent(newEvent)
      setShowExitModal(false)
    }
  }

  const handleStay = () => {
    document.addEventListener('click', clickOutsideHandler, true)
    setShowExitModal(false)
  }

  const clickOutsideHandler = useCallback((e) => {
    setEventStore({ target: e.target, event: e })
    const isNotBreadcrumbLink = !(e.target.id === 'breadcrumb-wrapper')
    const isDatoLink = e.target.id === 'dato-link'
    const isViewRubric = e.target.innerText === 'VIEW RUBRIC'
    const isSubmit = e.target.innerText === 'SUBMIT'
    if (isNotBreadcrumbLink || isDatoLink) {
      if (!isClickable(e.target) ||
        isViewRubric ||
        isSubmit) return
    }
    e.stopPropagation()
    e.preventDefault()
    document.removeEventListener('click', clickOutsideHandler, true)
    setShowExitModal(true)
  }, [])

  useEffect(() => {
    // fire clickOutsideHandler only for non admin accounts and when draft has been saved
    if (isDraft && !isAdmin) {
      document.addEventListener('click', clickOutsideHandler, true)
    }
    return () => {
      document.removeEventListener('click', clickOutsideHandler, true)
    }
  }, [isDraft, isAdmin, clickOutsideHandler])

  const { course: { showJDoodleCompiler } } = config
  return (
    <>
      {showExitModal && (
        <ExitModal
          show={showExitModal}
          handleStay={handleStay}
          handleLeave={handleLeave}
          lockTimeInSeconds={lockTimeInSeconds}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          primaryButtonText='Keep Editing'
          secondaryButtonText='Continue'
          text1='You’ve made changes to your work and chose to cancel. Press CONTINUE if you wish to keep your original submission. Any changes that you’ve made will be lost.'
          title='Are you sure you want to leave?'
          headerShow
          height={260}
          headerBgColor={'#25272B'}
          showHeaderBorder
          show={showConfirmationModal}
          handleClose={() => {
            updateContext({ cancelResubmission: false })
          }}
          handleConfirm={() => {
            updateContext({ cancelResubmission: false })
          }}
          handleDecline={() => {
            emitter.emit(ON_NAVIGATE_TO, '/')
            updateContext({
              cancelResubmission: false,
              showFinish: false,
              isMultiPartUploadingModal: false,
              isResubmitModal: false
            })
          }}
        />
      )}
      <SubmissionHeading>
        Submit Assignment
      </SubmissionHeading>
      <SubmissionParagraph marginBottom='15px' data-testid='assignmentSubmission'>
        {getSubmissionText()}
      </SubmissionParagraph>
      {affirmationStatements && multiPartUpload &&
        <CheckboxWrapper>
          {affirmationStatements.map((statement, i) => (
            <CustomCheckbox
              defalutValue={isResubmitPage && true}
              disabled={isMultiPartUploading}
              onChange={(val) => setCheckedAffirmations(prevVal => {
                return {
                  ...prevVal,
                  [`affirmation-${i}`]: val
                }
              })}
              key={i}
              label={statement}
            />
          ))}
        </CheckboxWrapper>
      }
      {!hideFileUploadField && multiPartUploadfiles?.map((file, i) => {
        return (
          <FileUploader
            key={i}
            index={i}
            uploadedContent={content?.[i]?.url ? content?.[i] : null}
            assignmentTitle={title}
            isCodingAssignment={isCodingAssignment}
            allowSpreadsheetUpload={allowSpreadsheetUpload}
            onFileSelect={(() => {
              if (multiPartUpload) return () => {}
              return isCodingAssignment
                ? handleCodingAssignmentFile
                : handleUploadedFile
            })()}
            isDisabled={isInputDisabled(activeInputStates.FILEUPLOAD)}
            uploadProgress={uploadProgress[i]}
            setActiveInput={setActiveInput}
            hasStarterFiles={hasStarterFiles}
            isResubmitPage={isResubmitPage}
            setTempFile={setTempFile}
            file={file}
            multiPartUpload={multiPartUpload}
            isMultiPartUploading={isMultiPartUploading}
            onUnsupportedFormat={(fileExtention) => {
              setModal({
                show: true,
                content: <>
                  <h1>We don't support this file type.</h1>
                  <p>
                    You uploaded a {fileExtention} file, but we support&nbsp;
                    {getSupportedFileTypes(file)} file extensions.
                  </p>
                  <SubmitButton className='btn btn-primary' onClick={() => {
                    setModal({ show: false })
                  }} >TRY AGAIN</SubmitButton>
                </>
              })
            }}
            onEmpty={() => {
              setActiveInput(activeInputStates.NONE)
              setFile(preVal => ({
                ...preVal,
                [i]: null
              }))
            }} />)
      })}
      <TextAreaWrap>
        {
          hideTextEntryField || hasStarterFiles || isCodingAssignment
            ? null
            : (
              <TextEditor
                textContent={content?.[0]?.text}
                onSave={handleText}
                setText={setText}
                isDisabled={isInputDisabled(activeInputStates.TEXTENTRY)}
                setActiveInput={setActiveInput}
                isResubmitPage={isResubmitPage}
                onEmpty={() => {
                  setActiveInput(activeInputStates.NONE)
                }} />
            )
        }
      </TextAreaWrap>
      {modal?.show && (
        <Modal>
          {modal.content}
        </Modal>
      )}
    </>
  )
}

export default AssignmentSubmissionPage
